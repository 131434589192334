import gql from "graphql-tag";

export const opportunityLinkFragment = gql`
  {
    id
    child {
      id
    }
    parent {
      id
    }
  }
`;
