/*
 *
 */
export const getTotalEconomies = async ({ state, effects }, data) => {
  const { economies } = await effects.gql.queries.economies(data);

  state.economy.totalRecords = economies ? economies.length : 0;
};

/*
 *
 */
export const getEconomies = async ({ state, effects }, data) => {
  console.log("action getEconomys...");
  try {
    let options = {};
    if (!data) {
      options = {
        first: state.economy.economyPerPage,
        skip: (state.economy.activePage - 1) * state.economy.economyPerPage,
      };
    } else {
      if (data && data.all) options = {};
      else {
        options = data;
        if (!data.first) options.first = state.economy.economyPerPage;
        if (!data.skip)
          options.skip =
            (state.economy.activePage - 1) * state.economy.economyPerPage;
      }
    }
    //
    const { economies } = await effects.gql.queries.economies(options);
    if (data && data.getValues) return economies;
    else state.economy.economies = economies;
  } catch (e) {
    console.log(e, "getEconomys errors");
  }
};

/*
 *
 */
export const saveEconomy = async ({ effects, actions }, data) => {
  try {
    return await effects.gql.mutations.saveEconomy(data);
  } catch (e) {
    console.log(e, "saveEconomy errors");
    actions.alert.showError({
      message:
        e.response && e.response.errors && e.response.errors.length
          ? e.response.errors[0]["message"]
          : "Error",
      title: "Saving Economy",
    });
  }
};

/*
 *
 */
export const onChangePage = async ({ state }, page) => {
  state.economy.activePage = page;
};

/*
 *
 */
export const onEconomyAdded = ({ state }, data) => {
  state.economy.push(data);
};
