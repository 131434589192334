import gql from "graphql-tag";
import { interviewScriptFragment } from "./fragments";

/*
 *
 */
export const interviewScripts = gql`
  query interviewScripts($where: InterviewScriptWhereInput, $orderBy: InterviewScriptOrderByInput, $skip: Int, $after: String, $before: String, $first: Int, $last: Int) {
    interviewScripts(where: $where, orderBy: $orderBy, skip: $skip, after: $after, before: $before, first: $first, last: $last) ${interviewScriptFragment}
  }
`;
