/*
*
*/
export const getTotalSurveys = async ({state, effects}, data) => {
  const {surveys} = await effects.gql.queries.surveys(data)

  state.survey.totalRecords = surveys ? surveys.length : 0
}

/*
*
*/
export const getSurveys = async ({state, effects}, data) => {
  console.log('action getSurveys...')
  try {
    let options = {}
    if (!data) {
      options = {
        first: state.survey.surveyPerPage,
        skip: (state.survey.activePage - 1) * state.survey.surveyPerPage
      }
    } else {
      if (data && data.all) options = {}
      else {
        options = data;
        if (!data.first) options.first = state.survey.surveyPerPage;
        if (!data.skip) options.skip = (state.survey.activePage - 1) * state.survey.surveyPerPage;
      }
    }
    //
    const {surveys} = await effects.gql.queries.surveys(options)
    console.log('surveys: ', surveys);
    if (data && data.getValues) return surveys
    else state.survey.surveys = surveys
  } catch (e) {
    console.log(e, 'getSurveys errors');
  }
}

/*
*
*/
export const saveSurvey = async ({effects, actions}, data) => {
  try {
    return await effects.gql.mutations.saveSurvey(data)
  } catch (e) {
    console.log(e, 'saveSurvey errors')
    actions.alert.showError({
      message: e.response && e.response.errors && e.response.errors.length ? e.response.errors[0]['message'] : 'Error',
      title: 'Saving Survey'
    });
  }
}

export const deleteSurvey = async ({effects, actions}, data) => {
  try {
    return await effects.gql.mutations.deleteSurvey(data)
  } catch (e) {
    console.log(e, 'deleteSurvey errors')
    actions.alert.showError({
      message: e.response && e.response.errors && e.response.errors.length ? e.response.errors[0]['message'] : 'Error',
      title: 'Saving Survey'
    });
  }
}

/*
*
*/
export const onChangePage = async ({state}, page) => {
  state.survey.activePage = page
}

/*
*
*/
export const onSurveyAdded = ({state}, data) => {
  state.survey.push(data)
}
