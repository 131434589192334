export const state = {
  ventures: {},
  isLoading: false,
  currentVentureId: null,
  venturePerPage: 10,
  totalRecords: 0,
  activePage: 1,
  ventureList: ventureNamespace =>
    Object.values(ventureNamespace.ventures)
      .sort((a, b) => {
        if (a.createdAt > b.createdAt) {
          return 1
        } else if (a.createdAt < b.createdAt) {
          return -1
        }

        return 0
      })
      .slice(0, ventureNamespace.venturePerPage)
}