import gql from 'graphql-tag';
import { opportunityLinkFragment } from './fragments';

/*
*
*/
export const saveOpportunityLink = gql`
  mutation saveOpportunityLink($data: OpportunityLinkUpdateInput!, $where: OpportunityLinkWhereUniqueInput) {
    saveOpportunityLink(data: $data, where: $where) ${opportunityLinkFragment}
  }
`;

/*
*
*/
export const deleteOpportunityLink = gql`
  mutation deleteOpportunityLink($where: OpportunityLinkWhereUniqueInput) {
    deleteOpportunityLink(where: $where) ${opportunityLinkFragment}
  }
`;
