<template>
  <span :class="`${isShow ? 'block' : 'hidden'} inline-flex justify-center items-center py-${py} bg-${bgColor} text-${textColor} px-${px} ${isRounded ? 'rounded-full' : 'rounded'} ${customClass}`">
    <Icon
      v-if="isLeft"
      :name="iconName"
      :color="textColor"
      :hover-text-color="textColor"
      :size="iconSize"
      class="px-1"
    />
    <Text
      :content="label"
      :color="textColor"
      :size="textSize"
      :weight="textWeight"
    />
    <Icon
      @click="isShow=false" 
      v-if="!isLeft"
      :name="iconName"
      :color="textColor"
      :hover-text-color="textColor"
      :size="iconSize"
      class="px-1"
    />
  </span>
</template>
<script>
import Icon from "../../../atoms/Icons/Icons.vue";
import Text from "../../../atoms/Text/Text.vue";
export default {
  name: "Basic",
  components: {
    Icon,
    Text,
  },
  props: {
    /**
    background color for badge
    */
    bgColor: {
      type: String,
      default: "",
    },
    /**
    text for badge
    */
    label: {
      type: String,
      default: "",
    },
    /**
    text color for badge and icon
    */
    textColor: {
      type: String,
      default: "",
    },
    /**
    text font size
    */
    textSize: {
      type: String,
      default: "xs",
    },
    /**
    icon size
    */
    iconSize: {
      type: String,
      default: "5",
    },
    /**
    horizontal padding for badge
    */
    px: {
      type: String,
      default: "",
    },
    /**
    vertical padding for badge
    */
    py: {
      type: String,
      default: "2",
    },
    /**
    switch between rounded and full rounded border for badge
    */
    isRounded: {
      type: Boolean,
      default: false,
    },
    /**
    use to switch position of icon left or right of label for badge
    */
    isLeft: {
      type: Boolean,
      default: false,
    },
    /**
    icon to be displayed inside badge
    */
    iconName: {
      type: String,
      default: "",
    },
    textWeight: {
      type: String,
      default: ""
    },
    customClass: {
      type: String,
      default: ""
    }
  },
  // data() {
  //   return {
  //     style: computed(() => {
  //       return `bg-${this.bgColor} text-${this.textColor} px-${this.px} ${this.isRounded ? 'rounded' : 'rounded-full'}`
  //     })
  //   }
  // }

  data() {
      return{
          isShow: true
      }
  },
};
</script>
