export const state = {
  targetCustomers: {},
  isLoading: false,
  currentTargetCustomerId: null,
  targetCustomerPerPage: 10,
  totalRecords: 20,
  activePage: 1,
  targetCustomerList: targetCustomerNamespace =>
    Object.values(targetCustomerNamespace.targetCustomers)
      .sort((a, b) => {
        if (a.createdAt > b.createdAt) {
          return 1
        } else if (a.createdAt < b.createdAt) {
          return -1
        }

        return 0
      })
      .slice(0, targetCustomerNamespace.targetCustomerPerPage)
}