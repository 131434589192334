import { keyBy, isEmpty } from "lodash";

/*
 *
 */
export const getTargetCustomers = async ({ state, effects }, data) => {
  console.log("action getTargetCustomers...");

  try {
    let options = {};

    if (isEmpty(data)) {
      options = {
        first: state.targetCustomer.targetCustomerPerPage,
        skip:
          (state.targetCustomer.activePage - 1) *
          state.targetCustomer.targetCustomerPerPage,
      };
    } else {
      options = data;
      if (!data.first) {
        options.first = state.targetCustomer.targetCustomerPerPage;
      }
      if (!data.skip) {
        options.skip =
          (state.targetCustomer.activePage - 1) *
          state.targetCustomer.targetCustomerPerPage;
      }
    }

    console.log(options, "getTargetCustomers options");

    const { targetCustomers } = await effects.gql.queries.targetCustomers(
      options
    );

    console.log(targetCustomers, "getTargetCustomers results");
    if (data?.getValues) {
      return targetCustomers;
    } else {
      state.targetCustomer.targetCustomers = keyBy(targetCustomers, "id");
    }
  } catch (e) {
    console.log(e, "getTargetCustomers errors");
  }
};

/*
 *
 */
export const saveTargetCustomer = async ({ effects }, data) => {
  return await effects.gql.mutations.saveTargetCustomer(data);
};

export const doesTargetCustomerExist = async ({ effects }, data) => {
  return await effects.gql.mutations.doesTargetCustomerExist(data);
};

export const getDemographicDataCharts = async ({ effects }, data) => {
  return await effects.gql.queries.getDemographicDataCharts(data);
};

export const onChangePerPage = ({ state }, perPage) => {
  state.targetCustomer.targetCustomerPerPage = perPage;
};
export const onChangePage = ({ state }, page) => {
  state.targetCustomer.activePage = page;
};
