import gql from 'graphql-tag';
import { programFragment } from './fragments';

/*
*
*/
export const programs = gql`
  query programs($where: ProgramWhereInput, $orderBy: ProgramOrderByInput, $skip: Int, $after: String, $before: String, $first: Int, $last: Int) {
    programs(where: $where, orderBy: $orderBy, skip: $skip, after: $after, before: $before, first: $first, last: $last) ${programFragment}
  }
`;
