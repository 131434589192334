import gql from 'graphql-tag';
import { opportunityFragment } from './fragments';

/*
*
*/
export const saveOpportunity = gql`
  mutation saveOpportunity($data: OpportunityUpdateInput!, $where: OpportunityWhereUniqueInput) {
    saveOpportunity(data: $data, where: $where) ${opportunityFragment}
  }
`;

/*
*
*/
export const deleteOpportunity = gql`
  mutation deleteOpportunity($where: OpportunityWhereUniqueInput) {
    deleteOpportunity(where: $where) ${opportunityFragment}
  }
`;
