/*
 *
 */
export const searchGooglePlaces = async ({ state, effects }, data) => {
  try {
    const results = await effects.gql.queries.searchGooglePlaces(data);
    console.log(results.searchGooglePlaces);
    results.searchGooglePlaces.map(i => i.name = (i.formatted_address || i.description))
    state.google.placeResults = [...results.searchGooglePlaces];
  } catch (e) {
    console.log(e, "searchGooglePlaces error");
    // actions.alert.showError(error.message, 'Location')
  }
};

export const emptyPlaceResults = ({ state }) => {
  state.google.placeResults = [];
};

export const searchGooglePlaceById = async ({ state, effects }, data) => {
  try {
    const { searchGooglePlaceById } =
      await effects.gql.queries.searchGooglePlaceById(data);
    return searchGooglePlaceById;
  } catch (e) {
    console.log(e, "searchGooglePlaceById error");
  }
};
