import gql from "graphql-tag";
import { economyFragment } from "./fragments";

/*
 *
 */
export const saveEconomy = gql`
  mutation saveEconomy($data: EconomyUpdateInput!, $where: EconomyWhereUniqueInput) {
    saveEconomy(data: $data, where: $where) ${economyFragment}
  }
`;

/*
 *
 */
export const deleteEconomy = gql`
  mutation deleteEconomy($where: EconomyWhereUniqueInput) {
    deleteEconomy(where: $where) ${economyFragment}
  }
`;
