import gql from 'graphql-tag';
import { tagFragment } from './fragments';

/*
*
*/
export const tags = gql`
  query tags($where: TagWhereInput, $orderBy: TagOrderByInput, $skip: Int, $after: String, $before: String, $first: Int, $last: Int) {
    tags(where: $where, orderBy: $orderBy, skip: $skip, after: $after, before: $before, first: $first, last: $last) ${tagFragment}
  }
`;
