import gql from "graphql-tag";

export const searchGooglePlaces = gql`
  query searchGooglePlaces($keyword: String! $types: [String] $componentRestrictions: Json) {
    searchGooglePlaces(keyword: $keyword types: $types componentRestrictions: $componentRestrictions)
  }
`;

export const searchGooglePlaceById = gql`
  query searchGooglePlaceById($placeId: String!) {
    searchGooglePlaceById(placeId: $placeId)
  }
`;
