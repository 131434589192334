import gql from "graphql-tag";

export const milestoneFragment = gql`
  {
    id
    name
    message
    modalMessage
    sortOrder
    video {
      id
      type
      source
      avatar
    }
    resources {
      id
      name
      type
    }
  }
`;
