import gql from 'graphql-tag';
import {targetCustomerFragment} from './fragments';

export const saveTargetCustomer = gql`
  mutation saveTargetCustomer($data: TargetCustomerUpdateInput!, $where:  TargetCustomerWhereUniqueInput) {
    saveTargetCustomer(data: $data, where: $where) ${targetCustomerFragment}
  }
`;

export const doesTargetCustomerExist = gql`
  mutation doesTargetCustomerExist($type: String $tags: Json) {
    doesTargetCustomerExist(type: $type tags: $tags)
  }
`
