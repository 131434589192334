export const state = {
  resources: {},
  isLoading: false,
  currentResourceId: null,
  resourcePerPage: 50,
  totalRecords: 0,
  activePage: 1,
  resourceList: resourceNamespace =>
    Object.values(resourceNamespace.resources)
      .sort((a, b) => {
        if (a.createdAt > b.createdAt) {
          return 1
        } else if (a.createdAt < b.createdAt) {
          return -1
        }

        return 0
      })
      .slice(0, resourceNamespace.resourcePerPage)
}
