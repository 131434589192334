/*
 *
 */
export const getTotalOpportunities = async ({ state, effects }, data) => {
  const { opportunities } = await effects.gql.queries.opportunities(data);

  state.opportunity.totalRecords = opportunities ? opportunities.length : 0;
};

/*
 *
 */
export const getOpportunities = async ({ state, effects }, data) => {
  console.log("action getOpportunities...");
  try {
    let options = {};
    if (!data) {
      options = {
        first: state.opportunity.opportunityPerPage,
        skip:
          (state.opportunity.activePage - 1) *
          state.opportunity.opportunityPerPage,
      };
    } else {
      if (data && data.all) options = {};
      else {
        options = data;
        if (!data.first) options.first = state.opportunity.opportunityPerPage;
        if (!data.skip)
          options.skip =
            (state.opportunity.activePage - 1) *
            state.opportunity.opportunityPerPage;
      }
    }
    //
    const { opportunities } = await effects.gql.queries.opportunities(options);
    if (data && data.getValues) return opportunities;
    else state.opportunity.opportunities = opportunities;
  } catch (e) {
    console.log(e, "getOpportunities errors");
  }
};

/*
 *
 */
export const saveOpportunity = async ({ effects, actions }, data) => {
  try {
    return await effects.gql.mutations.saveOpportunity(data);
  } catch (e) {
    console.log(e, "saveOpportunity errors");
    actions.alert.showError({
      message:
        e.response && e.response.errors && e.response.errors.length
          ? e.response.errors[0]["message"]
          : "Error",
      title: "Saving Opportunity",
    });
  }
};

export const deleteOpportunity = async ({ effects, actions }, data) => {
  try {
    return await effects.gql.mutations.deleteOpportunity(data);
  } catch (e) {
    console.log(e, "deleteOpportunity errors");
  }
};

export const getSurveySummary = async ({ effects }, data) => {
  return await effects.gql.queries.getSurveySummary(data);
};

/*
 *
 */
export const onChangePage = async ({ state }, page) => {
  state.opportunity.activePage = page;
};

/*
 *
 */
export const onOpportunityAdded = ({ state }, data) => {
  state.opportunity.push(data);
};
