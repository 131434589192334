import gql from 'graphql-tag';
import { economyFragment } from './fragments';

/*
*
*/
export const economies = gql`
  query economies($where: EconomyWhereInput, $orderBy: EconomyOrderByInput, $skip: Int, $after: String, $before: String, $first: Int, $last: Int) {
    economies(where: $where, orderBy: $orderBy, skip: $skip, after: $after, before: $before, first: $first, last: $last) ${economyFragment}
  }
`;
