import gql from "graphql-tag";
import { interviewScriptFragment } from "./fragments";
import { userFragment } from "@/store/effects/gql/user/fragments";

/*
 *
 */
export const saveInterviewScript = gql`
  mutation saveInterviewScript($data: InterviewScriptUpdateInput!, $where: InterviewScriptWhereUniqueInput) {
    saveInterviewScript(data: $data, where: $where) ${interviewScriptFragment}
  }
`;

/*
 *
 */
export const deleteInterviewScript = gql`
  mutation deleteInterviewScript($where: InterviewScriptWhereUniqueInput) {
    deleteInterviewScript(where: $where) ${interviewScriptFragment}
  }
`;

export const updateDefaultInterviewScriptMilestone = gql`
  mutation updateDefaultInterviewScriptMilestone($ventureId: String!) {
    updateDefaultInterviewScriptMilestone(ventureId: $ventureId) {
      id
    }
  }
`