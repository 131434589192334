export const avatarSizes = {
  6: "xs",
  8: "sm",
  10: "medium",
  12: "lg",
  14: "xl",
};

export const c = {
  register: {
    apiCalls: [
      {
        name: "mutation createAppUser",
        description: "use this mutation to create new user account",
        params: {
          email: "${email}",
          password: "${password}",
          sendEmailVerification: true,
        },
      },
    ],
    image: {
      src: require("../assets/images/login_page_sidepanel.png"),
      alt: "Your Company",
    },
    logo: {
      name: "DSCVRY",
      // alt: "Your Company",
      // src: "https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600",
      // width: "auto",
      // height: "12",
      // customClass: "mx-auto",
    },
    inputs: {
      email: {
        type: "text",
        label: "Email address",
        placeholder: "your@email.com",
        rounded: true,
        value: null,
      },
      password: {
        type: "password",
        label: "Password",
        placeholder: "********",
        rounded: true,
        value: null,
      },
      firstName: {
        type: "text",
        label: "First Name",
        placeholder: "",
        rounded: true,
        value: null,
      },
      lastName: {
        type: "text",
        label: "Last Name",
        placeholder: "",
        rounded: true,
        value: null,
      },
    },
    button: {
      label: "Next",
      customClass: "bg-green-500",
    },
    footerTitle: "Or continue with",
    icons: [
      {
        name: "PhoneIcon",
        size: 6,
        color: "gray-500",
      },
      {
        name: "PresentationChartBarIcon",
        size: 6,
        color: "gray-500",
      },
      {
        name: "AcademicCapIcon",
        size: 6,
        color: "gray-500",
      },
    ],
    terms: {
      title: "DSCVRY Terms And Conditions",
      content: [
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Id neque aliquam vestibulum morbi blandit cursus risus at ultrices. In cursus turpis massa tincidunt dui ut ornare lectus.",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Id neque aliquam vestibulum morbi blandit cursus risus at ultrices. In cursus turpis massa tincidunt dui ut ornare lectus.",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Id neque aliquam vestibulum morbi blandit cursus risus at ultrices. In cursus turpis massa tincidunt dui ut ornare lectus.",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Id neque aliquam vestibulum morbi blandit cursus risus at ultrices. In cursus turpis massa tincidunt dui ut ornare lectus.",
      ],
      btnText: "Close",
    },
  },
  login: {
    apiCalls: [
      {
        name: "mutation login",
        description: "use this mutation to login",
        params: {
          email: "${email}",
          password: "${password}",
        },
      },
    ],
    image: {
      src: require("../assets/images/login_page_sidepanel.png"),
      //"https://www.suburbandiagnostics.com/Content/assets/images/login-bg-img@2x.png",
      alt: "Your Company",
    },
    logo: {
      name: "DSCVRY",
      // alt: "Your Company",
      // src: "https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600",
      // width: "auto",
      // height: "12",
      // customClass: "mx-auto",
    },
    inputs: {
      email: {
        type: "text",
        label: "Email address",
        placeholder: "your@email.com",
        rounded: true,
        value: null,
      },
      password: {
        type: "password",
        label: "Password",
        placeholder: "********",
        rounded: true,
        value: null,
      },
    },
    button: {
      label: "Log In",
      customClass: "bg-green-500",
    },
    footerTitle: "Or continue with",
    icons: [
      {
        name: "PhoneIcon",
        size: 6,
        color: "gray-500",
      },
      {
        name: "PresentationChartBarIcon",
        size: 6,
        color: "gray-500",
      },
      {
        name: "AcademicCapIcon",
        size: 6,
        color: "gray-500",
      },
    ],
  },
  register_company: {
    image: {
      src: require("../assets/images/login_page_sidepanel.png"),
      //"https://www.suburbandiagnostics.com/Content/assets/images/login-bg-img@2x.png",
      alt: "Your Company",
    },
    logo: {
      name: "DSCVRY",
    },
    inputs: {
      name: {
        type: "text",
        label: "Organization Name",
        placeholder: "i.e. StartupHelpers",
        rounded: true,
        value: null,
      },
      website: {
        type: "text",
        label: "Website",
        placeholder: "startuphelpers.com",
        rounded: true,
        value: null,
      },
      type: {
        label: "Organization Type",
        placeholder: "Select a type",
        value: null,
        items: [
          { name: "PUBLIC", id: 0 },
          { name: "PRIVATE", id: 1 },
          { name: "NONPROFIT", id: 2 },
          { name: "GOVERNMENT", id: 3 },
          { name: "EDUCATION", id: 4 },
          { name: "PERSONAL", id: 5 },
        ],
      },
    },
    button: {
      label: "Log In",
      customClass: "bg-green-500",
    },
    footerTitle: "Or continue with",
    icons: [
      {
        name: "PhoneIcon",
        size: 6,
        color: "gray-500",
      },
      {
        name: "PresentationChartBarIcon",
        size: 6,
        color: "gray-500",
      },
      {
        name: "AcademicCapIcon",
        size: 6,
        color: "gray-500",
      },
    ],
  },
  setup_payment: {
    image: {
      src: require("../assets/images/login_page_sidepanel.png"),
      //"https://www.suburbandiagnostics.com/Content/assets/images/login-bg-img@2x.png",
      alt: "Your Company",
    },
    logo: {
      name: "DSCVRY",
    },
    inputs: {
      name: {
        type: "text",
        label: "Cardholder Name",
        placeholder: "i.e. Jane Smith",
        rounded: true,
        value: null,
        cols: 2,
      },
      number: {
        type: "text",
        label: "Card Number",
        placeholder: "",
        rounded: true,
        value: null,
        cols: 2,
        mask: "#### #### #### ####",
      },
      expiration: {
        type: "text",
        label: "Expiration",
        placeholder: "i.e. 01/25",
        rounded: true,
        value: null,
        cols: 1,
        mask: "##/##",
      },
      cvv: {
        type: "text",
        label: "Security Code",
        placeholder: "i.e. 123",
        rounded: true,
        value: null,
        cols: 1,
        mask: "###",
      },
    },
    button: {
      label: "Log In",
      customClass: "bg-green-500",
    },
  },
  invite_people: {
    image: {
      src: require("../assets/images/login_page_sidepanel.png"),
      //"https://www.suburbandiagnostics.com/Content/assets/images/login-bg-img@2x.png",
      alt: "Your Company",
    },
    logo: {
      name: "DSCVRY",
    },
    inputs: {
      emails: {
        type: "textarea",
        label:
          "Type or copy and paste as many email address as you'd like, separated by a comma and we will send them an email to join.",
        placeholder: "Enter one or multiple emails separated by a ','",
        rounded: true,
        value: null,
        cols: 2,
      },
      url: {
        type: "text",
        label:
          "If you'd like to email these people yourself, you can always send them the link below",
        placeholder: "",
        rounded: true,
        value: null,
        cols: 2,
        isButton: true,
      },
    },
    button: {
      label: "Log In",
      customClass: "bg-green-500",
    },
  },
  resetPassword: {
    apiCalls: [
      {
        name: "mutation passwordReset",
        description: "use this mutation to send email for resetting password",
        params: {
          email: "${email}",
        },
      },
    ],
    image: {
      src: require("../assets/images/login_page_sidepanel.png"),
      alt: "Your Company",
    },
    logo: {
      name: "DSCVRY",
      // alt: "Your Company",
      // src: "https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600",
      // width: "auto",
      // height: "12",
      // customClass: "mx-auto",
    },
    inputs: {
      email: {
        type: "text",
        label: "Email address",
        placeholder: "your@email.com",
        rounded: true,
        value: null,
      },
    },
    button: {
      label: "Next",
      customClass: "bg-green-500",
    },
  },
  newPassword: {
    apiCalls: [
      {
        name: "mutation passwordReset",
        description: "use this mutation to send email for resetting password",
        params: {
          email: "${email}",
        },
      },
    ],
    image: {
      src: require("../assets/images/login_page_sidepanel.png"),
      alt: "Your Company",
    },
    logo: {
      name: "DSCVRY",
      // alt: "Your Company",
      // src: "https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600",
      // width: "auto",
      // height: "12",
      // customClass: "mx-auto",
    },
    inputs: {
      password: {
        type: "password",
        label: "Password",
        placeholder: "********",
        rounded: true,
        value: null,
      },
    },
    button: {
      label: "Reset",
      customClass: "bg-green-500",
    },
  },
  resetPasswordSuccess: {
    image: {
      src: require("../assets/images/login_page_sidepanel.png"),
      alt: "Your Company",
    },
    logo: {
      name: "DSCVRY",
    },
  },
  verifyEmailStep: {
    image: {
      src: require("../assets/images/login_page_sidepanel.png"),
      alt: "Your Company",
    },
    logo: {
      name: "DSCVRY",
    },
  },
  sideNavigationBar: {
    title: "DSCVRY",
    user: {
      avatar:
        "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      name: "Tom Cook",
      role: "View profile",
    },
    logo: {
      isShow: false,
      src: "",
      title: "Workflow",
    },
    // navigation: [
    //   {id:1,name: 'Dashboard', count: '42',icon: 'HomeIcon', href: '/'},
    //   {id:2,name: 'Target Customers',count: '4', icon: 'BookOpenIcon', href: '/target-customers'},
    //   {id:3,name: 'Contacts', icon: 'UsersIcon', href: '/contacts'},
    //   {id:4,name: 'Opportunities', count: '12',icon: 'ExclamationIcon', href: '/opportunities'},
    //   {id:5,name: 'Survey Data', icon: 'MenuIcon', href: '/survey-data'},
    //   {id:6,name: 'Resources', count: '7', icon: 'LinkIcon', href: '/resources'},
    //   {id:7,name: 'Settings', icon: 'CogIcon', href: '/settings'},
    // ],
    navigation: [
      { id: 1, name: "Home", icon: "HomeIcon", href: "/" },
      {
        id: 2,
        name: "Ideas / Ventures",
        count: "4",
        icon: "BookOpenIcon",
        href: "/target-customers",
      },
      { id: 3, name: "", icon: "GroupIcon", href: "/contacts" },
      {
        id: 4,
        name: "Resources",
        count: "7",
        icon: "LinkIcon",
        href: "/resources",
      },
    ],
    navSelectedItem: { id: 3, name: "Projects", icon: "FolderIcon", href: "#" },
    navIconColor: "indigo-300",
    navBgColor: "bg_primary",
    navTextColor: "indigo-100",
    navTextSelectionColor: "white",
    navIconSelectionColor: "indigo-300",
    navHoverBg: "indigo-600",
    navHoverBgOpacity: "opacity-75",
    navSelectionBgColor: "indigo-800",
    navBadgeColor: "indigo-800",
    navBadgeSelectionColor: "indigo-600",
    profileTopBorderColor: "indigo-200",
    profileTitleTextColor: "white",
    profileSubtitleTextColor: "indigo-50",
    badgeSelectionColor: "indigo-600",
    badgeColor: "indigo-500",
  },
  content: {
    viewName: "targetCustomers",
    pageTotalRecords: 20,
    recordPerPage: 10,
    recordActivePage: 1,
    pagination: {
      totalRecords: 97,
      currentPage: 1,
      perPage: 10,
    },
    addItem: {
      title: "Add a new target customer",
      content:
        'Target Customers are large groups of people that might need your help, like "adults in physical therapy" or "millenial moms"',
    },
    items: [],

    listHeader: {
      title: "Target Customers",
      btnText: "Add Customer",
      count: 2,
      searchbox: {
        placeholder: "Find a Contact",
        icon: "SearchIcon",
      },
      filter: {
        items: [
          { id: 1, name: "Filter" },
          { id: 2, name: "Arlene Mccoy" },
          { id: 3, name: "Devon Webb" },
          { id: 4, name: "Tom Cook" },
          { id: 5, name: "Tanya Fox" },
        ],
        selectedItem: { id: 5, name: "Filter" },
      },
      sort: {
        items: [
          { id: 1, name: "Sort" },
          { id: 2, name: "Arlene Mccoy" },
          { id: 3, name: "Devon Webb" },
          { id: 4, name: "Tom Cook" },
          { id: 5, name: "Tanya Fox" },
        ],
        selectedItem: { id: 5, name: "Sort" },
      },
      links: {
        leftLinks: [
          {
            content: "View Interview Schedule",
            decoration: "underline",
          },
        ],
        rightLinks: [
          {
            content: "Import Contacts",
            decoration: "underline",
          },
          {
            content: "Export as CSV",
            decoration: "underline",
          },
        ],
      },
    },
    emptyViewBinding: {
      title: "Get started by adding your first idea!",
      description: "Once your add an idea, it will show up here",
      btnText: "Add an Idea",
      action: () => {},
      icon: "LightBulbIcon",
    },
  },
  venture_target_customers: {
    viewName: "targetCustomers",
    pageTotalRecords: 20,
    recordPerPage: 10,
    recordActivePage: 1,
    pagination: {
      totalRecords: 97,
      currentPage: 1,
      perPage: 10,
    },
    addItem: {
      title: "Add a new target customer",
      content:
        'Target Customers are large groups of people that might need your help, like "adults in physical therapy" or "millenial moms"',
    },
    items: [
      {
        title: "3. Heal as quickly as possible",
        actionLabel: "Individuals",
        subtitles: [
          {
            icon: "ExclamationIcon",
            name: "Opportunities",
            count: "7",
          },
          {
            icon: "UsersIcon",
            name: "Contacts",
            count: "30",
          },
          { name: "Interviews", count: 13, icon: "RefreshIcon" },
          { name: "Surveys", count: 9, icon: "ServerIcon" },
        ],
        customClass: "",
      },
      {
        title: "3. Heal as quickly as possible",
        actionLabel: "Individuals",
        subtitles: [
          {
            icon: "ExclamationIcon",
            name: "Opportunities",
            count: "7",
          },
          {
            icon: "UsersIcon",
            name: "Contacts",
            count: "30",
          },
          { name: "Interviews", count: 13, icon: "RefreshIcon" },
          { name: "Surveys", count: 9, icon: "ServerIcon" },
        ],
        customClass: "",
      },
    ],

    listHeader: {
      title: "Target Customers",
      btnText: "Add",
      count: 2,
      filter: {
        items: [
          { id: 1, name: "Filter" },
          { id: 2, name: "Arlene Mccoy" },
          { id: 3, name: "Devon Webb" },
          { id: 4, name: "Tom Cook" },
          { id: 5, name: "Tanya Fox" },
        ],
        selectedItem: { id: 5, name: "Filter" },
      },
      sort: {
        items: [
          { id: 1, name: "Sort" },
          { id: 2, name: "Arlene Mccoy" },
          { id: 3, name: "Devon Webb" },
          { id: 4, name: "Tom Cook" },
          { id: 5, name: "Tanya Fox" },
        ],
        selectedItem: { id: 5, name: "Sort" },
      },
      links: {
        leftLinks: [
          {
            content: "View Interview Schedule",
            decoration: "underline",
          },
        ],
        rightLinks: [
          {
            content: "Import Contacts",
            decoration: "underline",
          },
          {
            content: "Export as CSV",
            decoration: "underline",
          },
        ],
      },
    },
  },
  ecosystem_programs: {
    viewName: "targetCustomers",
    pageTotalRecords: 20,
    recordPerPage: 10,
    recordActivePage: 1,
    pagination: {
      totalRecords: 97,
      currentPage: 1,
      perPage: 10,
    },
    addItem: {
      title: "Add a new target customer",
      content:
        'Target Customers are large groups of people that might need your help, like "adults in physical therapy" or "millenial moms"',
    },
    items: [
      {
        title: "The Idea Factory",
        actionLabel: "8 weeks",
        subtitles: [
          {
            icon: "CalendarIcon",
            name: "Cohorts",
            count: "2",
          },
          {
            icon: "LightBulbIcon",
            name: "Ventures",
            count: "7",
          },
          {
            icon: "UsersIcon",
            name: "Team",
            count: "2",
          },
          {
            icon: "ExclamationIcon",
            name: "Opportunities",
            count: "0",
          },
        ],
        customClass: "",
      },
      {
        title: "The Idea Factory",
        actionLabel: "8 weeks",
        subtitles: [
          {
            icon: "CalendarIcon",
            name: "Cohorts",
            count: "2",
          },
          {
            icon: "LightBulbIcon",
            name: "Ventures",
            count: "7",
          },
          {
            icon: "UsersIcon",
            name: "Team",
            count: "2",
          },
          {
            icon: "ExclamationIcon",
            name: "Opportunities",
            count: "0",
          },
        ],
        customClass: "",
      },
    ],

    listHeader: {
      title: "Programs",
      btnText: "Add",
      count: 2,
      filter: {
        items: [
          { id: 1, name: "Filter" },
          { id: 2, name: "Arlene Mccoy" },
          { id: 3, name: "Devon Webb" },
          { id: 4, name: "Tom Cook" },
          { id: 5, name: "Tanya Fox" },
        ],
        selectedItem: { id: 5, name: "Filter" },
      },
      sort: {
        items: [
          { id: 1, name: "Sort" },
          { id: 2, name: "Arlene Mccoy" },
          { id: 3, name: "Devon Webb" },
          { id: 4, name: "Tom Cook" },
          { id: 5, name: "Tanya Fox" },
        ],
        selectedItem: { id: 5, name: "Sort" },
      },
      links: {
        leftLinks: [
          {
            content: "View Interview Schedule",
            decoration: "underline",
          },
        ],
        rightLinks: [
          {
            content: "Import Contacts",
            decoration: "underline",
          },
          {
            content: "Export as CSV",
            decoration: "underline",
          },
        ],
      },
    },
  },
  venture_teams: {
    viewName: "targetCustomers",
    pageTotalRecords: 20,
    recordPerPage: 10,
    recordActivePage: 1,
    addItem: {
      title: "Add a new target customer",
      content:
        'Target Customers are large groups of people that might need your help, like "adults in physical therapy" or "millenial moms"',
    },
    items: [],
    emptyViewBinding: {
      title: "Want to do this as an accelerator?",
      description:
        "product discovery can be an overwhelming process, especially if it's your first time. Adding this venture to our accelerator for $99 will focus your experience around exactly what you need to do next, give you extra help content, and also access to your private Slack community.",
      btnText: "Get Started",
      action: () => {},
      icon: "BoltIcon",
    },
    listHeader: {
      title: "Venture Teams",
      btnText: "Invite",
      count: 35,
      filter: {
        items: [
          { id: 2, name: "Milestone" },
          { id: 3, name: "Program" },
          { id: 4, name: "Cohort" },
          { id: 5, name: "Clear" },
        ],
        selectedItem: { id: 5, name: "Filter" },
      },
      sort: {
        items: [
          { id: 1, name: "Name" },
          { id: 5, name: "Target Customers" },
          { id: 2, name: "Opportunities" },
          { id: 3, name: "Contacts" },
          { id: 4, name: "Interviews" },
          { id: 6, name: "Created Date" },
          { id: 7, name: "Clear" },
        ],
        selectedItem: { id: 7, name: "Sort" },
      },
      links: {
        leftLinks: [
          {
            content: "View Interview Schedule",
            decoration: "underline",
          },
        ],
        rightLinks: [
          {
            content: "Import Contacts",
            decoration: "underline",
          },
          {
            content: "Export as CSV",
            decoration: "underline",
          },
        ],
      },
      searchbox: {
        placeholder: "Find a team",
        icon: "SearchIcon",
      },
    },
  },
  ventureOpportunities: {
    viewName: "targetCustomers",
    pageTotalRecords: 20,
    recordPerPage: 10,
    recordActivePage: 1,
    pagination: {
      totalRecords: 97,
      currentPage: 1,
      perPage: 10,
    },
    addItem: {
      title: "Add a new target customer",
      content:
        'Target Customers are large groups of people that might need your help, like "adults in physical therapy" or "millenial moms"',
    },
    items: [
      {
        title: "2. Share their progress with their physical therapist",
        actionLabel: "Action",
        subtitles: [
          {
            icon: "MapIcon",
            name: "Adults in physical therapy",
          },
          {
            icon: "LinkIcon",
            name: "Linked Opps",
            count: "7",
          },
          {
            icon: "ChatIcon",
            name: "Interview",
            count: "4",
          },
          {
            icon: "TrendingUpIcon",
            name: "Responses",
            count: "20",
          },
        ],
        survey: {
          Magnitude: 30,
          "Job Done Frequency": "89%",
          Frequency: "4.5x/week",
          Desire: "+2.5",
          Experience: "-1.5",
          Market: "9.5M",
        },
        customClass: "",
      },
      {
        title: "2. Share their progress with their physical therapist",
        actionLabel: "Action",
        subtitles: [
          {
            icon: "MapIcon",
            name: "Adults in physical therapy",
          },
          {
            icon: "LinkIcon",
            name: "Linked Opps",
            count: "7",
          },
          {
            icon: "ChatIcon",
            name: "Interview",
            count: "4",
          },
          {
            icon: "TrendingUpIcon",
            name: "Responses",
            count: "20",
          },
        ],
        survey: {
          Magnitude: 30,
          "Job Done Frequency": "89%",
          Frequency: "4.5x/week",
          Desire: "+2.5",
          Experience: "-1.5",
          Market: "9.5M",
        },
        customClass: "",
      },
    ],
    listHeader: {
      title: "Opportunities",
      btnText: "Add Opportunity",
      count: 2,
      searchbox: {
        placeholder: "Search",
        icon: "SearchIcon",
      },
      filter: {
        items: [
          {
            id: 0,
            name: "Type",
            children: [
              { id: 1, name: "Motivator" },
              { id: 2, name: "Activity" },
              { id: 3, name: "Solution" },
            ],
          },
        ],
        selectedItem: null,
      },
      sort: {
        items: [
          {
            id: 0,
            name: "Name",
            children: [
              {
                id: 0,
                name: "A -> Z",
              },
              {
                id: 1,
                name: "Z -> A",
              },
            ],
          },
          {
            id: 1,
            name: "Linked Opportunities",
            children: [
              {
                id: 0,
                name: "1 -> 9",
              },
              {
                id: 1,
                name: "9 -> 1",
              },
            ],
          },
          {
            id: 2,
            name: "Interviews",
            children: [
              {
                id: 0,
                name: "1 -> 9",
              },
              {
                id: 1,
                name: "9 -> 1",
              },
            ],
          },
          {
            id: 3,
            name: "Survey Responses",
            children: [
              {
                id: 0,
                name: "1 -> 9",
              },
              {
                id: 1,
                name: "9 -> 1",
              },
            ],
          },
          {
            id: 4,
            name: "Magnitude",
            children: [
              {
                id: 0,
                name: "1 -> 9",
              },
              {
                id: 1,
                name: "9 -> 1",
              },
            ],
          },
          {
            id: 5,
            name: "Created Date",
            children: [
              {
                id: 0,
                name: "1/1 -> 12/31",
              },
              {
                id: 1,
                name: "12/31 -> 1/1",
              },
            ],
          },
        ],
        selectedItem: null,
      },
      links: {
        leftLinks: [
          {
            content: "View Interview Schedule",
            decoration: "underline",
          },
        ],
        rightLinks: [
          {
            content: "Import Contacts",
            decoration: "underline",
          },
          {
            content: "Export as CSV",
            decoration: "underline",
          },
        ],
      },
    },
  },
  ecosystemOpportunities: {
    viewName: "targetCustomers",
    pageTotalRecords: 20,
    recordPerPage: 10,
    recordActivePage: 1,
    pagination: {
      totalRecords: 97,
      currentPage: 1,
      perPage: 10,
    },
    addItem: {
      title: "Add a new target customer",
      content:
        'Target Customers are large groups of people that might need your help, like "adults in physical therapy" or "millenial moms"',
    },
    items: [
      {
        title: "2. Share their progress with their physical therapist",
        actionLabel: "Action",
        subtitles: [
          {
            icon: "MapIcon",
            name: "Adults in physical therapy",
          },
          {
            icon: "LinkIcon",
            name: "Linked Opps",
            count: "7",
          },
          {
            icon: "ChatIcon",
            name: "Interview",
            count: "4",
          },
          {
            icon: "TrendingUpIcon",
            name: "Responses",
            count: "20",
          },
        ],
        survey: {
          Magnitude: 30,
          "Job Done Frequency": "89%",
          Frequency: "4.5x/week",
          Desire: "+2.5",
          Experience: "-1.5",
          Market: "9.5M",
        },
        customClass: "",
      },
      {
        title: "2. Share their progress with their physical therapist",
        actionLabel: "Action",
        subtitles: [
          {
            icon: "MapIcon",
            name: "Adults in physical therapy",
          },
          {
            icon: "LinkIcon",
            name: "Linked Opps",
            count: "7",
          },
          {
            icon: "ChatIcon",
            name: "Interview",
            count: "4",
          },
          {
            icon: "TrendingUpIcon",
            name: "Responses",
            count: "20",
          },
        ],
        survey: {
          Magnitude: 30,
          "Job Done Frequency": "89%",
          Frequency: "4.5x/week",
          Desire: "+2.5",
          Experience: "-1.5",
          Market: "9.5M",
        },
        customClass: "",
      },
    ],
    listHeader: {
      title: "Opportunities",
      btnText: "Add",
      count: 2,
      searchbox: {
        placeholder: "Search",
        icon: "SearchIcon",
      },
      filter: {
        items: [
          { id: 1, name: "Filter" },
          { id: 2, name: "Arlene Mccoy" },
          { id: 3, name: "Devon Webb" },
          { id: 4, name: "Tom Cook" },
          { id: 5, name: "Tanya Fox" },
        ],
        selectedItem: { id: 5, name: "Filter" },
      },
      sort: {
        items: [
          { id: 1, name: "Sort" },
          { id: 2, name: "Arlene Mccoy" },
          { id: 3, name: "Devon Webb" },
          { id: 4, name: "Tom Cook" },
          { id: 5, name: "Tanya Fox" },
        ],
        selectedItem: { id: 5, name: "Sort" },
      },
      links: {
        leftLinks: [
          {
            content: "View Interview Schedule",
            decoration: "underline",
          },
        ],
        rightLinks: [
          {
            content: "Import Contacts",
            decoration: "underline",
          },
          {
            content: "Export as CSV",
            decoration: "underline",
          },
        ],
      },
    },
  },
  opportunities: {
    pagination: {
      totalRecords: 97,
      currentPage: 1,
      perPage: 10,
    },
    addItem: {
      title: "Add a new target customer",
      content:
        'Target Customers are large groups of people that might need your help, like "adults in physical therapy" or "millenial moms"',
    },
    items: [
      {
        title: "3. Heal as quickly as possible",
        actionLabel: "Motivator",
        subtitles: [
          {
            icon: "MapIcon",
            name: "Adults in physical therapy",
          },
          {
            icon: "LinkIcon",
            name: "Linked Opps",
            count: "7",
          },
          {
            icon: "ChatIcon",
            name: "Interview",
            count: "4",
          },
        ],
        customClass: "",
      },
      {
        title: "3. Heal as quickly as possible",
        actionLabel: "Motivator",
        subtitles: [
          {
            icon: "MapIcon",
            name: "Adults in physical therapy",
          },
          {
            icon: "LinkIcon",
            name: "Linked Opps",
            count: "7",
          },
          {
            icon: "ChatIcon",
            name: "Interview",
            count: "4",
          },
        ],
        customClass: "",
      },
    ],

    listHeader: {
      title: "Opportunities",
      count: 2,
      searchbox: {
        placeholder: "Search",
        icon: "SearchIcon",
      },
      filter: {
        items: [
          { id: 1, name: "Filter" },
          { id: 2, name: "Arlene Mccoy" },
          { id: 3, name: "Devon Webb" },
          { id: 4, name: "Tom Cook" },
          { id: 5, name: "Tanya Fox" },
        ],
        selectedItem: { id: 5, name: "Filter" },
      },
      sort: {
        items: [
          { id: 1, name: "Sort" },
          { id: 2, name: "Arlene Mccoy" },
          { id: 3, name: "Devon Webb" },
          { id: 4, name: "Tom Cook" },
          { id: 5, name: "Tanya Fox" },
        ],
        selectedItem: { id: 5, name: "Sort" },
      },
      links: {
        leftLinks: [
          {
            content: "View Interview Schedule",
            decoration: "underline",
          },
        ],
        rightLinks: [
          {
            content: "Import Contacts",
            decoration: "underline",
          },
          {
            content: "Export as CSV",
            decoration: "underline",
          },
        ],
      },
    },
  },
  detailViewHeader: {
    title: "#13 Keep track of what exercises to do",
    options: [],
    backUrl: "",
    backLabel: "Target Customers",
    actionUrl: "",
    actionLabel: "",
    isActive: true,
  },
  targetCustomerHeader: {
    title: "#13 Keep track of what exercises to do",
    options: ["Archive"],
    backUrl: "",
    backLabel: "Target Customers",
    actionUrl: "",
    actionLabel: "",
    isActive: true,
  },
  subNavigationBar: [
    {
      name: "Details",
      count: null,
      href: "#",
      icon: "PencilIcon",
      current: false,
    },
    {
      name: "Interviews",
      count: 13,
      href: "#",
      icon: "RefreshIcon",
      current: false,
    },
    { name: "Surveys", count: 9, href: "#", icon: "ServerIcon", current: true },
  ],
  opportunityDataDetail: {
    title: "Survey Summary",
    navigationGraph: {
      experience: {
        name: "Experience",
        percent: "60",
        value: "-1.9",
      },
      magnitude: {
        name: "Magnitude",
        percent: "49.9",
      },
      desire: {
        name: "Desire",
        percent: "80",
        value: "+2.5",
      },
      outOfMarket: {
        name: "Out Of Market",
        percent: "35%",
      },
      inMarket: {
        name: "In Market",
        percent: "65%",
      },
      earlyAdopter: {
        name: "Early Adopter",
        percent: "12%",
      },
    },
    demographics: [
      {
        title: "",
        content: [
          {
            name: "Out",
            count: 13,
            color: "gray",
            tooltip: ["Out Motivation (1-24)", "13 people (30.3%)"],
          },
          {
            name: "Low",
            count: 13,
            color: "orange",
            tooltip: ["Low Motivation (1-24)", "13 people (30.3%)"],
          },
          {
            name: "Mid",
            count: 7,
            color: "yellow",
            tooltip: ["Mid Motivation (1-24)", "13 people (30.3%)"],
          },
          {
            name: "High",
            count: 3,
            color: "green",
            tooltip: ["High Motivation (1-24)", "13 people (30.3%)"],
          },
        ],
      },
    ],
    accordions: {
      name: "Strategy Recommendations",
    },

    // leftAccordions: {
    //     name: 'About this section',
    //     items: [
    //         'Multiple strap configurations Spacious interior with top zip MultiDouble stitched construction',
    //     ],
    // },
    tables: {
      header: [],
      body: [
        ["Magnitude", "29.9"],
        ["Desire", "+2.5"],
        ["Experience", "-1.9"],
        ["Action Done", "95%"],
        ["Frequency", "12x/week"],
        ["Out of Market", "35%"],
        ["In Market", "65%"],
        ["Adjusted Market", "18,000,000"],
        ["Early Adopters", "12%"],
        ["Early Adopter Size", "3,300,000"],
      ],
    },
    // mdTables: [
    //     {
    //         header: [],
    //         body: [
    //             ["Magnitude", "29.9"],
    //             ["Desire", "+2.5"],
    //             ["Experience", "-1.9"],
    //             ["Activity", "95%"],
    //             ["Frequency", "12x/week"],
    //         ]
    //         // More people...
    //     },
    //     {
    //         header: [],
    //         body: [
    //             ["Out of Market", "35%"],
    //             ["In Market", "65%"],
    //             ["Adjusted Market", "18,000,000"],
    //             ["Early Adopters", "12%"],
    //             ["Early Adopter Size", "3,300,000"],
    //         ]
    //         // More people...
    //     },
    // ],
    description:
      "Multiple strap configurations Spacious interior with top zip MultiDouble stitched construction",
    information: `
    <div>
    <div class="mt-2">
    <b>Name: </b>  &nbsp;Should be good balance between simple and description.
    </div>
    <div class="mt-2">
        <b>Type: </b>  &nbsp;Individuals make this a B2C venture. Employees, businesses, schools, or governments make this a B2B or B2G venture.
    </div>
    <div class="mt-2">
        <b>Demographics: </b>  &nbsp;Combines any age, income-level, gender, or ethnicity tags you selected when creating this target customer.
    </div>
    <div class="mt-2">
        <b>Interests and Activities: </b>  &nbsp;Tags that specify what they might like (interests) or things they might do (activities).
    </div>
    <div class="mt-2">
        <b>Market: </b>  &nbsp;The geographical boundary for this group. Defaults to the United States.
    </div>
    <div class="mt-2">
        <b>Estimated Market Size: </b>  &nbsp;Based on your research, and how many people are in this Target Customer Group. This is required to later calculate things like "adjusted market size."
    </div>
    <div class="mt-2">
        <b>Research Source: </b>  &nbsp;Add a url or other citation information when people ask how you calculated your Estimated and Adjusted market size.
    </div>
    <div class="mt-2 mb-8">
        <b>Note: </b>  &nbsp;Should be good balance between simple and description.
    </div>
    </div>`,
  },
  formFieldGroup: [
    {
      header: "Target Customer Details",
      description:
        "A Target Customer is the largest possible group of people that you might be able to help",
      isForm: true,
      name: {
        value: "",
        label: "Name",
      },
      estimatedMarketSize: {
        value: "",
        label: "Estimated Market Size",
      },
      estimatedAnnualIncome: {
        value: "",
        label: "Estimated Annual Income",
      },
      employeeSize: {
        value: "",
        label: "Employee Size",
      },
      researchSource: {
        value: "",
        label: "Research Source",
      },
      notes: {
        value: "",
        label: "Notes",
      },
      types: {
        list: [
          { id: 0, name: "INDIVIDUALS" },
          { id: 1, name: "EMPLOYEES" },
          { id: 2, name: "BUSINESSES" },
          { id: 3, name: "ORGANIZATIONS" },
          { id: 4, name: "GOVERNMENTS" },
        ],
        label: "Type",
      },
      age: {
        list: [],
        label: "Age (i.e. ONLY adults, or ONLY 55-64 years old)",
        isMulti: true,
        value: [],
      },
      gender: {
        list: [],
        label: "Gender (i.e. ONLY women or ONLY non-binary)",
        isMulti: true,
        value: [],
      },
      ethnicity: {
        list: [],
        label:
          "Race/Enthicity (i.e. ONLY of Hispanic, LatinX, or Spanish origin)",
        isMulti: true,
        value: [],
      },
      income: {
        list: [],
        label: "Income (i.e. ONLY low income)",
        isMulti: true,
        value: [],
      },
      interestAndActivities: {
        list: [],
        label: "Descriptors",
        isMulti: true,
      },
      markets: {
        list: [],
        label: "Market",
        isMulti: true,
      },
      open: false,
      iconText: "View Map",
      iconName: "MapIcon",
      displayTableOnly: true,
      displayHeaders: false,
      detailHidden: false,
      textHtmlMobile: `
            <div>
            <div class="mt-2">
            <b>Name: </b>  &nbsp;Should be good balance between simple and description.
            </div>
            <div class="mt-2">
                <b>Type: </b>  &nbsp;Individuals make this a B2C venture. Employees, businesses, schools, or governments make this a B2B or B2G venture.
            </div>
            <div class="mt-2">
                <b>Demographics: </b>  &nbsp;Combines any age, income-level, gender, or ethnicity tags you selected when creating this target customer.
            </div>
            <div class="mt-2">
                <b>Interests and Activities: </b>  &nbsp;Tags that specify what they might like (interests) or things they might do (activities).
            </div>
            <div class="mt-2">
                <b>Market: </b>  &nbsp;The geographical boundary for this group. Defaults to the United States.
            </div>
            <div class="mt-2">
                <b>Estimated Market Size: </b>  &nbsp;Based on your research, and how many people are in this Target Customer Group. This is required to later calculate things like "adjusted market size."
            </div>
            <div class="mt-2">
                <b>Research Source: </b>  &nbsp;Add a url or other citation information when people ask how you calculated your Estimated and Adjusted market size.
            </div>
            <div class="mt-2 mb-8">
                <b>Note: </b>  &nbsp;Should be good balance between simple and description.
            </div>
            </div>`,
      textHtml: `
                <div class="text-grayhelptext">
                  <div class="mt-2">
                    Name:  &nbsp;Should be good balance between simple and description.
                  </div>
                  <div class="mt-2">
                    Type: &nbsp;Individuals make this a B2C venture. Employees, businesses, schools, or governments make this a B2B or B2G venture.
                  </div>
                  <div class="mt-2">
                    Demographics:  &nbsp;Combines any age, income-level, gender, or ethnicity tags you selected when creating this target customer.
                  </div>
                  <div class="mt-2">
                    Interests and Activities:  &nbsp;Tags that specify what they might like (interests) or things they might do (activities).
                  </div>
                  <div class="mt-2">
                    Market:  &nbsp;The geographical boundary for this group. Defaults to the United States.
                  </div>
                  <div class="mt-2">
                    Estimated Market Size:  &nbsp;Based on your research, and how many people are in this Target Customer Group. This is required to later calculate things like "adjusted market size."
                  </div>
                  <div class="mt-2">
                    Research Source:  &nbsp;Add a url or other citation information when people ask how you calculated your Estimated and Adjusted market size.
                  </div>
                  <div class="mt-2 mb-8">
                    Note: &nbsp;Should be good balance between simple and description.
                  </div>
                </div>`,
    },
  ],
  home: {
    title: "Welcome to DSCVRY!",
    description: "Let's find the next big problem to solve",
    profile: {
      avatar: "",
      name: "Test User",
    },
    empty: {
      title: "Get started by adding your first idea!",
      description: "Once your add an idea, it will show up here",
      btnText: "Add an Idea",
      action: () => {},
      icon: "LightBulbIcon",
    },
    ideas: [
      {
        title: "Range",
        description:
          "A platform to connect homeowners with professionals so that can figure out whether",
        actionLabel: "Motivator",
        subtitles: [
          {
            icon: "MapIcon",
            name: "Target Customers",
            count: "2",
          },
          {
            icon: "ExclamationIcon",
            name: "Opportunities",
            count: "7",
          },
          {
            icon: "UsersIcon",
            name: "Team",
            count: "2",
          },
        ],
        customClass: "",
      },
      {
        title: "Range",
        description:
          "A platform to connect homeowners with professionals so that can figure out whether",
        actionLabel: "Motivator",
        subtitles: [
          {
            icon: "MapIcon",
            name: "Target Customers",
            count: "2",
          },
          {
            icon: "ExclamationIcon",
            name: "Opportunities",
            count: "7",
          },
          {
            icon: "UsersIcon",
            name: "Team",
            count: "2",
          },
        ],
        customClass: "",
      },
    ],
    ideaHeader: {
      title: "Ideas and Ventures (7)",
      btnText: "Add Idea",
    },
    resources: [
      {
        title: "Building Blocks 1: Learning and Adapting to Risk",
        imgSrc: "https://i.ibb.co/WtkFj23/unnamed-2.jpg",
        desc: "Setting up a mental model for how to look at product discovery",
        items: [
          {
            icon: "LinkIcon",
            label: "Article",
          },
          {
            label: "Getting Started",
          },
        ],
        customClass: "",
      },
      {
        title: "Building Blocks 1: Learning and Adapting to Risk",
        imgSrc: "https://i.ibb.co/WtkFj23/unnamed-2.jpg",
        desc: "Setting up a mental model for how to look at product discovery",
        items: [
          {
            icon: "LinkIcon",
            label: "Article",
          },
          {
            label: "Getting Started",
          },
        ],
        customClass: "",
      },
    ],
    ecosystems: [
      {
        title: "Startup Virginia",
        description:
          "A mobile app to help people keep track of their physical therapy exercises at home",
        subtitles: [
          {
            icon: "LightBulbIcon",
            name: "Ventures",
            count: "32",
          },
          {
            icon: "NewspaperIcon",
            name: "Programs",
            count: "2",
          },
          {
            icon: "UsersIcon",
            name: "Cohorts",
            count: "7",
          },
        ],
        customClass: "",
      },
      {
        title: "Startup Virginia",
        description:
          "A mobile app to help people keep track of their physical therapy exercises at home",
        subtitles: [
          {
            icon: "LightBulbIcon",
            name: "Ventures",
            count: "32",
          },
          {
            icon: "NewspaperIcon",
            name: "Programs",
            count: "2",
          },
          {
            icon: "UsersIcon",
            name: "Cohorts",
            count: "7",
          },
        ],
        customClass: "",
      },
    ],
  },
  frequencyOptions: [
    { id: 0, name: "times per minute", value: "MINUTE" },
    { id: 1, name: "times per hour", value: "HOUR" },
    { id: 2, name: "times per day", value: "DAY" },
    { id: 3, name: "times per week", value: "WEEK" },
    { id: 4, name: "times per month", value: "MONTH" },
    { id: 5, name: "times per quarter", value: "QUARTER" },
    { id: 6, name: "times per year", value: "YEAR" },
    { id: 7, name: "times per 5 years", value: "FIVE_YEARS" },
    { id: 8, name: "times per 10 years", value: "TEN_YEARS" },
  ],
  ecosystem: {
    title: "Welcome to DSCVRY!",
    description: "Let's find the next big problem to solve",
    cards: [
      {
        icon: "BulbIcon",
        category: "Venture Teams",
        count: 35,
      },
      {
        icon: "UsersIcon",
        category: "People",
        count: 92,
      },
      {
        icon: "ExclamationIcon",
        category: "Opportunities",
        count: 149,
      },
    ],
    cohorts: [
      {
        title: "Test",
        actionLabel: "Motivator",
        subtitles: [
          {
            icon: "LightBulbIcon",
            name: "Ventures",
            count: "2",
          },
          {
            icon: "UsersIcon",
            name: "People",
            count: "10",
          },
          {
            icon: "ExclamationIcon",
            name: "Opportunities",
            count: "0",
          },
        ],
        customClass: "",
      },
      {
        title: "Test",
        actionLabel: "Motivator",
        subtitles: [
          {
            icon: "LightBulbIcon",
            name: "Ventures",
            count: "2",
          },
          {
            icon: "UsersIcon",
            name: "People",
            count: "10",
          },
          {
            icon: "ExclamationIcon",
            name: "Opportunities",
            count: "0",
          },
        ],
        customClass: "",
      },
    ],
    programs: [
      {
        title: "The Idea Factory",
        actionLabel: "8 weeks",
        subtitles: [
          {
            icon: "CalendarIcon",
            name: "Cohorts",
            count: "2",
          },
          {
            icon: "LightBulbIcon",
            name: "Ventures",
            count: "7",
          },
          {
            icon: "UsersIcon",
            name: "Team",
            count: "2",
          },
          {
            icon: "ExclamationIcon",
            name: "Opportunities",
            count: "0",
          },
        ],
        customClass: "",
      },
      {
        title: "The Idea Factory",
        actionLabel: "8 weeks",
        subtitles: [
          {
            icon: "CalendarIcon",
            name: "Cohorts",
            count: "2",
          },
          {
            icon: "LightBulbIcon",
            name: "Ventures",
            count: "7",
          },
          {
            icon: "UsersIcon",
            name: "Team",
            count: "2",
          },
          {
            icon: "ExclamationIcon",
            name: "Opportunities",
            count: "0",
          },
        ],
        customClass: "",
      },
    ],
    resources: [
      {
        title: "Building Blocks 1: Learning and Adapting to Risk",
        imgSrc: "https://i.ibb.co/WtkFj23/unnamed-2.jpg",
        desc: "Setting up a mental model for how to look at product discovery",
        items: [
          {
            icon: "LinkIcon",
            label: "Article",
          },
          {
            label: "Getting Started",
          },
        ],
        customClass: "",
      },
      {
        title: "Building Blocks 1: Learning and Adapting to Risk",
        imgSrc: "https://i.ibb.co/WtkFj23/unnamed-2.jpg",
        desc: "Setting up a mental model for how to look at product discovery",
        items: [
          {
            icon: "LinkIcon",
            label: "Article",
          },
          {
            label: "Getting Started",
          },
        ],
        customClass: "",
      },
    ],
    ecosystems: [
      {
        title: "Startup Virginia",
        description:
          "A mobile app to help people keep track of their physical therapy exercises at home",
        subtitles: [
          {
            icon: "LightBulbIcon",
            name: "Ventures",
            count: "32",
          },
          {
            icon: "NewspaperIcon",
            name: "Programs",
            count: "2",
          },
          {
            icon: "UsersIcon",
            name: "Cohorts",
            count: "7",
          },
        ],
        customClass: "",
      },
      {
        title: "Startup Virginia",
        description:
          "A mobile app to help people keep track of their physical therapy exercises at home",
        subtitles: [
          {
            icon: "LightBulbIcon",
            name: "Ventures",
            count: "32",
          },
          {
            icon: "NewspaperIcon",
            name: "Programs",
            count: "2",
          },
          {
            icon: "UsersIcon",
            name: "Cohorts",
            count: "7",
          },
        ],
        customClass: "",
      },
    ],
  },
  add_program: {
    isShow: true,
    title: "Let's add a new program to your ecosystem",
    content: "You'll be able to create cohorts and invite teams to join",
    isLoading: true,
    formGroup: [
      {
        type: "text",
        label: "Name",
        placeholder: "i.e. IdeaMachine or Entrepreneurship 101",
        value: null,
      },
      {
        type: "select",
        label: "Type",
        placeholder: "Select a program type",
        items: [
          {
            id: 1,
            name: "select1",
          },
          {
            id: 2,
            name: "select2",
          },
          {
            id: 3,
            name: "select3",
          },
        ],
        value: null,
      },
      {
        type: "text",
        label: "Duration (week)s",
        placeholder: "Enter the number of weeks",
        value: null,
      },
      // {
      //   type: "select",
      //   label: "Admins for this program",
      //   placeholder: "Select people from your team",
      //   items: [
      //     {
      //       id: 1,
      //       name: "select4",
      //     },
      //     {
      //       id: 2,
      //       name: "select5",
      //     },
      //     {
      //       id: 3,
      //       name: "select6",
      //     },
      //   ],
      //   value: null,
      // },
    ],
    button: {
      label: "",
      bgColor: "",
    },
  },
  survey_response_detail: {
    isShow: true,
    title: "#313 keep track of what exercises todo",
    content: "You'll be able to create cohorts and invite teams to join",
    isLoading: true,
  },
  range: {
    title: "Range",
    description:
      "A mobile physical therapy app to help track exercises at home",
    emptyViewBinding: {
      title: "Want to do this as an accelerator?",
      description:
        "product discovery can be an overwhelming process, especially if it's your first time. Adding this venture to our accelerator for $99 will focus your experience around exactly what you need to do next, give you extra help content, and also access to your private Slack community.",
      btnText: "Get Started",
      action: () => {},
      icon: "BoltIcon",
    },
    opportunity: {
      title: "Opportunity Data",
      description:
        "Now that you have real data back from potential customers, you can do an apples-to-apples comparison to figure out the most viable combination of target customer segments and the problems those people have.",
      items: [
        {
          Opportunity: "#20 Do their exercises",
          "Target Customer": "Adults in physical",
          Responses: "29",
          Magnitude: 27,
          Market: "10.1M",
        },
      ],
    },
    resources: [
      {
        title: "Building Blocks 1: Learning and Adapting to Risk",
        imgSrc: "https://i.ibb.co/WtkFj23/unnamed-2.jpg",
        desc: "Setting up a mental model for how to look at product discovery",
        items: [
          {
            icon: "LinkIcon",
            label: "Article",
          },
          {
            label: "Getting Started",
          },
        ],
        customClass: "",
      },
      {
        title: "Building Blocks 1: Learning and Adapting to Risk",
        imgSrc: "https://i.ibb.co/WtkFj23/unnamed-2.jpg",
        desc: "Setting up a mental model for how to look at product discovery",
        items: [
          {
            icon: "LinkIcon",
            label: "Article",
          },
          {
            label: "Getting Started",
          },
        ],
        customClass: "",
      },
    ],
    ecosystems: [
      {
        title: "Startup Virginia",
        description:
          "A mobile app to help people keep track of their physical therapy exercises at home",
        subtitles: [
          {
            icon: "LightBulbIcon",
            name: "Ventures",
            count: "32",
          },
          {
            icon: "NewspaperIcon",
            name: "Programs",
            count: "2",
          },
          {
            icon: "UsersIcon",
            name: "Cohorts",
            count: "7",
          },
        ],
        customClass: "",
      },
      {
        title: "Startup Virginia",
        description:
          "A mobile app to help people keep track of their physical therapy exercises at home",
        subtitles: [
          {
            icon: "LightBulbIcon",
            name: "Ventures",
            count: "32",
          },
          {
            icon: "NewspaperIcon",
            name: "Programs",
            count: "2",
          },
          {
            icon: "UsersIcon",
            name: "Cohorts",
            count: "7",
          },
        ],
        customClass: "",
      },
    ],
    empty: {
      title: "Want to do this as an accelerator?",
      description:
        "product discovery can be an overwhelming process, especially if it's your first time. Adding this venture to our accelerator for $99 will focus your experience around exactly what you need to do next, give you extra help content, and also access to your private Slack community.",
      btnText: "Get Started",
      action: () => {},
      icon: "BoltIcon",
    },
    research: {
      title: "Research Progress",
      badge: "Getting Started",
      description:
        "The end goal is generate actionable opportunity data directly from your potential customers. To do this, you generally need at least 100 contacts to send a survey to. 12 interviews to identify the most prevalent opportunities to add to your survey. and at least 30 survey responses.",
      items: [
        {
          "Target Customer": "Adults in physical theraphy",
          Opportunities: 0,
          Contacts: "94 / 100",
          "Scheduled Interviews": 4,
          "Completed Interviews": "4 / 12",
          "Survey Responses": "20 / 30",
        },
      ],
    },
    venture: {
      title: "Your Venture Team",
      btnText: "settings",
      members: [
        {
          name: "L",
          role: "Owner",
          contacts: 0,
          interviews: 0,
          Opportunities: 0,
        },
        {
          name: "G",
          role: "Member",
          contacts: 0,
          interviews: 0,
          Opportunities: 0,
        },
      ],
      inviteBtnText: "Invite someone",
    },
  },
  venture_team: {
    title: "Range",
    description:
      "A mobile physical therapy app to help track exercises at home",
    resources: [
      {
        title: "Building Blocks 1: Learning and Adapting to Risk",
        imgSrc: "https://i.ibb.co/WtkFj23/unnamed-2.jpg",
        desc: "Setting up a mental model for how to look at product discovery",
        items: [
          {
            icon: "LinkIcon",
            label: "Article",
          },
          {
            label: "Getting Started",
          },
        ],
        customClass: "",
      },
      {
        title: "Building Blocks 1: Learning and Adapting to Risk",
        imgSrc: "https://i.ibb.co/WtkFj23/unnamed-2.jpg",
        desc: "Setting up a mental model for how to look at product discovery",
        items: [
          {
            icon: "LinkIcon",
            label: "Article",
          },
          {
            label: "Getting Started",
          },
        ],
        customClass: "",
      },
    ],
    ecosystems: [
      {
        title: "Startup Virginia",
        description:
          "A mobile app to help people keep track of their physical therapy exercises at home",
        subtitles: [
          {
            icon: "LightBulbIcon",
            name: "Ventures",
            count: "32",
          },
          {
            icon: "NewspaperIcon",
            name: "Programs",
            count: "2",
          },
          {
            icon: "UsersIcon",
            name: "Cohorts",
            count: "7",
          },
        ],
        customClass: "",
      },
      {
        title: "Startup Virginia",
        description:
          "A mobile app to help people keep track of their physical therapy exercises at home",
        subtitles: [
          {
            icon: "LightBulbIcon",
            name: "Ventures",
            count: "32",
          },
          {
            icon: "NewspaperIcon",
            name: "Programs",
            count: "2",
          },
          {
            icon: "UsersIcon",
            name: "Cohorts",
            count: "7",
          },
        ],
        customClass: "",
      },
    ],
    empty: {
      title: "How is collecting survey data going?",
      description:
        "Looks like you are well on your way to getting opportunity data from your survey. Once you get at least 30 responses for a Target Customer we can walk you through how to intrepret the data and think about next steps. Keep it up!",
      badge: "Collecting Survey Data",

      btnText: "Managing survey responses",
      action: () => {},
      icon: "MenuIcon",
    },
    research: {
      title: "Research Progress",
      badge: "Getting Started",
      description:
        "The end goal is generate actionable opportunity data directly from your potential customers. To do this, you generally need at least 100 contacts to send a survey to. 12 interviews to identify the most prevalent opportunities to add to your survey. and at least 30 survey responses.",
      items: [
        {
          "Target Customer": "Adults in physical theraphy",
          Opportunities: 0,
          Contacts: "94 / 100",
          "Scheduled Interviews": 4,
          "Completed Interviews": "4 / 12",
          "Survey Responses": "20 / 30",
        },
      ],
    },
    opportunity: {
      title: "Opportunity Data",
      description:
        "Now that you have real data back from potential customers, you can do an apples-to-apples comparison to figure out the most viable combination of target customer segments and the problems those people have.",
      items: [
        {
          Opportunity: "#20 Do their exercises",
          "Target Customer": "Adults in physical",
          Responses: "29",
          Magnitude: 27,
          Market: "10.1M",
        },
      ],
    },
    venture: {
      title: "Your Venture Team",
      btnText: "settings",
      members: [
        {
          name: "L",
          role: "Owner",
          contacts: 0,
          interviews: 0,
          Opportunities: 0,
        },
        {
          name: "G",
          role: "Member",
          contacts: 0,
          interviews: 0,
          Opportunities: 0,
        },
      ],
      inviteBtnText: "Invite someone",
    },
  },
  welcome: {
    title: "Welcome to DSCVRY - we're glad you're here!",
    textHtml:
      "<p>We are here to turn your big ideas into the fuel that powers the product discovery  process so you can build healthy businesses that solve big problems.</p>",
    videoTitle: "DSCVRY in 60 seconds",
    videoUrl: "",
    noBtnText: "Next",
  },
  welcomeEcosystem: {
    title: "Welcome to DSCVRY - we're glad you're here!",
    textHtml:
      "<p>We are here to help you future founders turn their big ideas into the fuel that powers the product discovery process so they can build healthy businesses that solve big problems.</p>",
    videoTitle: "DSCVRY for Ecosystems in 30 seconds",
    videoUrl: "",
    noBtnText: "Next",
  },
  inviteMember: {
    isShow: true,
    title: "Invite someone to join your venture team!",
    content: "Let others join and contribute to your venture.",
    isLoading: true,
    formGroup: [
      {
        type: "email",
        label: "Email Address",
        placeholder: "Email Address",
      },
      {
        type: "select",
        label: "Role",
        placeholder: "",
        items: [
          {
            id: 1,
            name: "Member (add/edit)",
          },
        ],
        selectedItem: {},
      },
    ],
    submitBtnText: "Invite",
  },
  invitePeople: {
    isShow: true,
    title: "Let's invite some people to join the ecosystem.",
    content:
      "This will allow you to see their progress and help them through their product discovery journey",
    isLoading: true,
    formGroup: [
      {
        type: "textarea",
        label:
          "Type or copy and paste as many email address as you'd like, seperated by a comma. We will send them an email with a link allowing them to either sign up for an account or pick which idea they'd like to use if they already have an account.",
        placeholder: 'Enter one or multiple emails separated by a ","',
      },
    ],
    submitBtnText: "Invite",
  },
  inviteUsers: {
    title: "Let's invite some people as ecosystem users.",
    content:
      "This will let them help manage your ecosystem and help your venture teams",

    formGroup: [
      {
        type: "textarea",
        label:
          "Type or copy and paste as many email address as you'd like, seperated by a comma. We will send them an email with a link allowing them to either sign up for an account or pick which idea they'd like to use if they already have an account.",
        placeholder: 'Enter one or multiple emails separated by a ","',
      },
    ],
    submitBtnText: "Invite",
  },
  settings: {
    profile: {
      profile: {
        avatar: "",
        name: "Test User",
        description: "member since 4/1/2022",
      },
      options: ["Sign out"],
      label: "Options",
    },
    details: {
      firstName: {
        label: "First Name",
        name: "firstName",
        type: "string",
        value: null,
      },
      lastName: {
        label: "Last Name",
        name: "lastName",
        type: "string",
        value: null,
      },
      email: {
        label: "Email",
        name: "email",
        type: "email",
        value: null,
      },
    },
    demographics: [
      {
        list: [
          { id: 1, name: "12-17" },
          { id: 2, name: "18-24" },
          { id: 3, name: "25-34" },
        ],
        selected: { id: 5, name: "12-17" },
        label: "Age",
        cols: 1,
      },
      {
        list: [
          { id: 1, name: "Lower income (under $50k)" },
          { id: 2, name: "Middle income ($50k - $150k)" },
          { id: 3, name: "Higher income (over $150k)" },
          { id: 4, name: "Prefer not to say" },
        ],
        selected: { id: 1, name: "Lower income (under $50k)" },
        label: "Income",
        cols: 1,
      },
      {
        list: [
          { id: 1, name: "Male" },
          { id: 2, name: "Female" },
          { id: 3, name: "Prefer not to say" },
          { id: 4, name: "Self describe" },
        ],
        selected: { id: 5, name: "Male" },
        label: "Gender",
        cols: 1,
      },
      {
        list: [{ id: 1, name: "RichMond, VA" }],
        selected: { id: 1, name: "RichMond, VA" },
        label: "Location",
        cols: 1,
      },
      {
        list: [
          { id: 1, name: "American Indian or Alaskan Native" },
          { id: 2, name: "Asian" },
          { id: 3, name: "Black or African American" },
          { id: 4, name: "Hispanic LatinX or Spanish Origin" },
          { id: 5, name: "Middle Eastern or North African" },
          { id: 6, name: "Native Hawaiian or Pacific Islander" },
          { id: 7, name: "White" },
          { id: 8, name: "Prefer not to say" },
          { id: 9, name: "Self describe" },
        ],
        selected: { id: 1, name: "RichMond, VA" },
        label: "Race/Enthicity",
        cols: 1,
      },
      {
        list: [],
        label: "Interests and Activities",
        description:
          'If an interest or activity isn\'t on the list, you can type it in and hit "enter" and it will be added as a new tag.',
        cols: 2,
      },
    ],
    contacts: [
      {
        type: "Email",
        icon: "MailIcon",
        value: "",
      },
      {
        type: "Twitter",
        icon: "MailIcon",
        value: "@lu",
      },
    ],
    transactions: [],
  },
  venture_settings: {
    profile: {
      profile: {
        avatar: "",
        name: "Test User",
        description: "member since 4/1/2022",
      },
      options: ["Archive Venture"],
      label: "Options",
    },
    info: {
      name: {
        label: "Name",
        name: "name",
        type: "text",
        value: null,
      },
      idea: {
        label: "Idea",
        name: "idea",
        type: "textarea",
        value: null,
      },
    },
    payments: [
      {
        type: "Visa",
        last4: 2345,
      },
      {
        type: "Visa",
        last4: 2345,
      },
    ],
    contacts: [
      {
        type: "Email",
        icon: "MailIcon",
        value: "",
      },
      {
        type: "Twitter",
        icon: "MailIcon",
        value: "@lu",
      },
    ],
    ecosystems: [
      {
        name: "Startup Virginia",
        date: new Date(),
      },
    ],
    programs: [
      {
        name: "The Idea Factory",
        description: "Summar 22 Cohort",
      },
    ],
  },
  ecosystem_settings: {
    profile: {
      profile: {
        avatar: "",
        name: "Test User",
        description: "member since 4/1/2022",
      },
      options: ["Sign out"],
      label: "Options",
    },
    paymentForm: [
      {
        label: "Name on card",
        name: "name",
        type: "text",
        placeholder: "i.e. Jane Smith",
        value: null,
        cols: 2,
      },
      {
        label: "Card Number",
        name: "number",
        type: "text",
        mask: "#### #### #### ####",
        placeholder: "i.e. 1234 1234 1234 5678",
        value: null,
        cols: 2,
      },
      {
        label: "Expiration",
        name: "expiration",
        type: "text",
        mask: "##/##",
        placeholder: "i.e. 01/22",
        value: null,
        cols: 1,
      },
      {
        label: "Zip Code",
        name: "zipcode",
        type: "text",
        mask: "#####",
        placeholder: "i.e. 12345",
        value: null,
        cols: 1,
      },
      {
        label: "Security Code",
        name: "cvv",
        type: "text",
        mask: "###",
        placeholder: "i.e. 123",
        value: null,
        cols: 1,
      },
    ],
    info: {
      name: {
        label: "Name",
        name: "name",
        type: "text",
        value: null,
      },
      type: {
        label: "Organization Type",
        name: "type",
        type: "select",
        value: null,
        items: [
          { name: "PUBLIC", id: 0 },
          { name: "PRIVATE", id: 1 },
          { name: "NONPROFIT", id: 2 },
          { name: "GOVERNMENT", id: 3 },
          { name: "EDUCATION", id: 4 },
          { name: "PERSONAL", id: 5 },
        ],
      },
    },
    payments: [
      {
        type: "Visa",
        last4: 2345,
      },
      {
        type: "Visa",
        last4: 2345,
      },
    ],
    contacts: [
      {
        type: "Email",
        icon: "MailIcon",
        value: "",
      },
      {
        type: "Twitter",
        icon: "MailIcon",
        value: "@lu",
      },
    ],
    ecosystems: [
      {
        name: "Startup Virginia",
        date: new Date(),
      },
    ],
    programs: [
      {
        name: "The Idea Factory",
        description: "Summar 22 Cohort",
      },
    ],
  },
  validateEmail: {
    title: "Let's validate your email",
    description:
      "Just click the button below or copy and paste the line back into your browser to validate your email address.",
    btnText: "Click to Validate",
    link: "https://app.dscvry.co/#/login",
  },
  addIdea: {
    isShow: true,
    title: "Add a New Idea",
    content:
      "Ideas are potential solutions to hypothesized problems. Let's walk through who needs your help and why.",
    isLoading: true,
  },
  demographics: {
    image: {
      src: require("../assets/images/login_page_sidepanel.png"),
      alt: "Your Company",
    },
    items: [
      {
        label: "About how old are you?",
        type: "RADIO",
        options: [],
        value: null,
      },
      {
        label: "How would you describe your gender",
        type: "RADIO",
        options: [],
        value: null,
        inputValue: null,
      },
      {
        label: "With which racial and ethic group(s) do you identify?",
        type: "CHECKBOX",
        options: [],
        value: [],
        inputValue: null,
      },
      {
        label: "In which city or state do you live in?",
        type: "AUTOCOMPLETE",
        placeholder: "Start typing",
        value: [],
      },
      {
        label: "What is your level of household income?",
        type: "RADIO",
        options: [],
        value: null,
      },
    ],
  },
  interests: {
    image: {
      src: require("../assets/images/login_page_sidepanel.png"),
      alt: "Your Company",
    },
  },
  demographicQuestions: [
    {
      label: "About how old are you?",
      dataType: "AGE",
      type: "RADIO",
      options: [],
      value: null,
    },
    {
      label: "How would you describe your gender?",
      type: "RADIO",
      dataType: "GENDER",
      options: [],
      value: null,
      inputValue: null,
    },
    {
      label: "With which racial and ethic group(s) do you identify?",
      type: "CHECKBOX",
      dataType: "ETHNICITY",
      options: [],
      value: [],
      inputValue: null,
    },
    {
      label: "In which city or state do you live in?",
      type: "MULTISELECT",
      dataType: "LOCATION",
      placeholder: "Start typing",
      value: [],
    },
    {
      label: "What is your level of household income?",
      type: "RADIO",
      dataType: "INCOME",
      options: [],
      value: null,
    },
    {
      label: "What are your interest and activities?",
      type: "MULTISELECT",
      dataType: "DESCRIPTORS",
      placeholder: "Start typing",
      radioOptions: ["Interest", "Activity"],
      value: [],
    },
  ],
  emailTemplates: [
    {
      name: "Interview Request",
      nameLower: "interview request",
      type: "INTERVIEW",
      version: 1,
      subject: "Quick 30 minute conversation?",
      body: `<div>Hi {{firstName}}, would you be willing to have a 30 minute conversation with me? As a {{targetCustomerUser.name}}, I'm sure you {{ researchPrompt }} and I'd greatly appreciate it if you'd be willing to share some of your experiences with me as I'm trying to learn about the space and maybe start a business to help solve some problems. Let me know what day and time you might be free, I'd be happy to work around your schedule. <br><br> <div v-if="relationship === 'strange'">Just to be clear, I promise I'm not selling anything and just hoping to learn from you. <br><br></div> Thanks so much!</div>`,
      metadata: {
        outreachTemplateParams: [
          "firstName",
          "targetCustomer",
          "researchPrompt",
          "relationship",
        ],
      },
      isArchived: false,
    },
    {
      name: "Interview Request Follow Up",
      nameLower: "interview request follow up",
      type: "INTERVIEW_FOLLOW_UP",
      version: 1,
      subject: "re: a quick conversation",
      body: `<div>Hi {{ firstName }}, just bringing this to the top of your inbox. Do you have time for a brief conversation about how you {{ researchPrompt }}?. It'd be a huge help in finishing my research.<br><br><div v-if="relationship === 'strange'">Again, I promise I'm not selling anything and just hoping to learn from you.<br><br></div>Thanks!</div>`,
      metadata: {
        outreachTemplateParams: ["firstName", "researchPrompt", "relationship"],
      },
      isArchived: false,
    },
    {
      name: "Interview Day Of Reminder",
      nameLower: "interview day of reminder",
      type: "DAY_OF_REMINDER",
      version: 1,
      subject: "Our conversation at {{ interviewTime + timezone }}",
      body: `<div>Hi {{ firstName }}, thanks so much again for being willing to talk today! I just wanted to send a few details before our conversation today at {{ interviewTime + timezone }}. If you need to reschedule, just let me know and I'd be happy to find another time that will work better.<br><br>- We'll be talking for 30 minutes and I'll do my best to be mindful of your time<br>- It would be really helpful if I could record our conversation but it's completely fine if you'd prefer not to<br>- My hope is to ask just a few simple questions and have you share some stories about how you {{ researchPrompt }}<br><br>Let me know if you have any questions in the meantime and I look forward to talking with you soon!</div>`,
      metadata: {
        outreachTemplateParams: [
          "interviewTime",
          "timezone",
          "firstName",
          "researchPrompt",
          "relationship",
        ],
      },
      isArchived: false,
    },
    {
      name: "Thank You Sent",
      nameLower: "thank you sent",
      type: "THANK_YOU",
      version: 1,
      subject: "Thanks so much for talking!",
      body: `<div>{{ firstName }} - thank you so much again for your time! If there's anyone that comes to mind that might be interested in talking with me, I'd be incredibly grateful for the intro and would be happy to send you some content to forward and make it as easy as possible.<br><br>Also I'll be sending out a very short survey in a week or two just to confirm my findings which I'd be happy to share the results of with you if you're interested.<br><br>Thanks again {{ firstName }}!</div>`,
      metadata: {
        outreachTemplateParams: ["firstName"],
      },
      isArchived: false,
    },
    {
      name: "Survey Request",
      nameLower: "survey request",
      type: "SURVEY",
      version: 1,
      subject: "Help me finish my research in 60 seconds",
      body: `<div>Hi {{ firstName }}, would you be willing to answer a few quick questions to help me finish my research? I've had a lot of great conversations with people like you and I'm sending this out to finish out what I've learned. It should only take you a minute.<br><br>{{ contactSurveyLink }}<br><br>Thank you!</div>`,
      metadata: {
        outreachTemplateParams: ["firstName", "contactSurveyLink"],
      },
      isArchived: false,
    },
    {
      name: "Survey Follow Up",
      nameLower: "survey follow up",
      type: "SURVEY_FOLLOW_UP",
      version: 1,
      subject: "re: help finishing my research",
      body: `<div>I know you're busy but wanted to quickly check if you'd be willing to answer a few simple questions to help me finish my research. It should only take a minute and would be a huge help.<br><br>{{ contactSurveyLink }}<br><br>Thank you!</div>`,
      metadata: {
        outreachTemplateParams: ["firstName", "contactSurveyLink"],
      },
      isArchived: false,
    },
  ],
  interviewScript: {
    name: "Default Interview Script",
    introScript: `<ul><li><p>Hi {{ firstName }}, thank you so much for being generous with your time. I'll do my best to keep this to just 30 minutes.</p></li>
      <li><p>Like I said, I'm talking with {{targetCustomerUser.name}} like you about how they {{researchPrompt}}.</p></li>
      <li><p>I'm going to ask you a few questions and mostly just want to get to know a bit of your story.</p></li>
      <li><p>Would you mind if I record this so I can take notes later? It's completely ok if you'd rather not.</p></li>
    </ul>`,
    questionScript: `<ul><li><p>How do you/When was the last time you had to {{researchPrompt}}?</p></li>
    <li><p>Tell me a little about what led up to that...where you were, who you were with, what was going on in your life (this is to help jog their memory)</p></li>
    <li><p>Now tell me about that experience...walk me through it.</p></li>
    <li><p>What did you expect going into it/How did you feel about it after?</p></li></ul>
    <br><br>
    FOLLOW UPS
    <br><br>
    <ul><li><p>You mentioned that you [do something], tell me more about that</p></li>
    <li><p>How did you come about doing things in that way?</p></li>
    <li><p>What do you like/not like about doing things that way?</p></li>
    <li><p>What else do you use that for?</p></li>
    <li><p>Tell me more about [that person you mentioned]</p></li>
    <li><p>Tell me more about why you feel that way?</p></li>
    <li><p>It sounds like things changed over time - why is that?</p></li>
    <li><p>Have you ever had to do that and it went really well/poorly?</p></li>
    <li><p>If you had a magic wand, what would you change about that experience?</p></li></ul>`,
    outroScript: `<ul><li><p>Thanks so much, {{firstName}}!</p></li>
    <li><p>If it's ok with you, in a week or two I'll follow up with a short 30 second survey just to confirm some of my findings.</p></li>
    <li><p>I'd also be happy to share what I've learned with you in the next few weeks if you'd be interested.</p></li>
    <li><p>Last thing: Do you know any else who also does these types of things that might be willing to talk? I'd be really thankful if you could forward an email to them from me if you're willing.</p></li></ul>`,
    metadata: { interviewScriptParams: ["firstName", "researchPrompt"] },
    isArchived: false,
  },
};
