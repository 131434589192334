import gql from "graphql-tag";
import { ventureFragment } from "./fragments";

export const saveVenture = gql`
  mutation saveVenture($data: VentureUpdateInput!, $where:  VentureWhereUniqueInput) {
    saveVenture(data: $data, where: $where) {
      id
    }
  }
`;

export const purgeVenture = gql`
  mutation purgeVenture($ventureId: String!) {
    purgeVenture(ventureId: $ventureId)
  }
`;

export const addVentureTo = gql`
  mutation addVentureTo(
    $cohortId: String
    $userId: String!
    $ventureId: String!
    $ecosystemId: String
  ) {
    addVentureTo(
      cohortId: $cohortId
      userId: $userId
      ventureId: $ventureId
      ecosystemId: $ecosystemId
    )
  }
`;

export const removeVentureFrom = gql`
  mutation removeVentureFrom(
    $cohortId: String
    $userId: String!
    $ventureId: String!
    $ecosystemId: String
  ) {
    removeVentureFrom(
      cohortId: $cohortId
      userId: $userId
      ventureId: $ventureId
      ecosystemId: $ecosystemId
    )
  }
`;
