import { Settings } from "../../settings";
import * as internalActions from "./internalActions";
import store from "store/dist/store.modern";
import { _ } from "vue-underscore";
import { json } from "overmind";

// const TOKEN_NAME = Settings.appName.toLowerCase() + '-token';
const TOKEN_NAME =
  "@" + Settings.appName.toLowerCase().replace(/s/g, "") + ":token";

export const internal = internalActions;

/*
 *
 */
export const isProfileUnset = async ({ state }) => {
  return (
    _.isEmpty(state.currentUser.email) ||
    _.isEmpty(state.currentUser.fullName) ||
    _.isEmpty(state.currentUser.id)
  );
};

/*
 *
 */
export const createAppUser = async ({ state, effects, actions }, variables) => {
  console.log("creating app user...", variables);

  try {
    const user = await effects.gql.mutations.createAppUser(variables);
    console.log(user.createAppUser.user, "createAppUser");
    if (variables.sendEmailVerification) {
      state.currentUser = user.createAppUser.user;
    }
    // await actions.user.onCreateBraintreeToken();
    return user.createAppUser;
  } catch (e) {
    console.log(e, "createAppUser errors");
  }
};

/*
 *
 */
export const verifySmsCode = async ({ effects }, variables) => {
  console.log("verifying sms code...", variables);
  const result = await effects.gql.mutations.verifySmsCode(variables);
  return result.verifySmsCode;
};

/*
 *
 */
export const setStoredAuthToken = async ({ state }) => {
  return store.set(TOKEN_NAME, {
    token: state.authToken,
    userId: state.currentUser.id,
  });
};

/*
 *
 */
export const getStoredAuthToken = async () => {
  if (!store.get(TOKEN_NAME))
    return store.set(TOKEN_NAME, { token: null, userId: null });
  return store.get(TOKEN_NAME);
};

/*
 *
 */
export const removeStoredAuthToken = async () => {
  return store.remove(TOKEN_NAME);
};

/*
 *
 */
export const logout = async ({ state, actions }) => {
  console.log("Logout");
  state.currentUser = null;
  state.isLoggedIn = false;
  state.authToken = null;
  state.currentCart = null;
  await localStorage.removeItem("chewbox_currentUser");
  await localStorage.removeItem("chewbox_currentCart");
  await localStorage.removeItem("chewbox_order");
  await localStorage.removeItem("chewbox_user");
  await actions.removeStoredAuthToken();
  return true;
};

/*
 *
 */
export const login = async ({ effects, state, actions }, variables) => {
  try {
    const { login } = await effects.gql.mutations.login(variables);

    state.currentUser = login.user;
    if (state.currentUser?.emails[0].verified) {
      state.isLoggedIn = true;
      state.authToken = login.token;
    }
    await actions.setStoredAuthToken();
    return true;
  } catch (e) {
    console.log(e, "e");
    actions.alert.showError({ message: e.response.errors[0]["message"] });
    await actions.removeStoredAuthToken();
    state.errors = e.response.errors;
  }
};

export const setCategories = async ({ state }, data) => {
  state.selectedCategories = [...data];
};
/*
 *
 */
export const loginWithToken = async (
  { effects, state, actions },
  variables
) => {
  console.log("logging in with token...", variables);

  if (!variables.token || !variables.userId) {
    state.isAuthenticating = false;
    await actions?.logout();
    return;
  }

  try {
    const { loginWithToken } = await effects.gql.mutations.loginWithToken(
      variables
    );

    state.currentUser = loginWithToken.user;
    if (state.currentUser?.emails[0].verified) {
      state.isLoggedIn = true;
      if (state.currentUser?.company)
        actions.setCurrentCompany(json(state.currentUser?.company));
    } else {
      (await actions) && actions.removeStoredAuthToken();
      (await actions) && actions.logout();
      if (!state.currentUser?.emails[0].verified) {
        return "NOT_VERIFIED";
      }
    }
    state.authToken = variables.token;
    state.isAuthenticating = false;
    return true;
  } catch (e) {
    console.log("e", e.message, Object.keys(e));
    if (
      e.message?.includes("Could not verify you") ||
      e.message?.includes("No user found with user id")
    ) {
      state.isAuthenticating = false;
      await actions?.removeStoredAuthToken();
      await actions?.logout();
      if (state.route) {
        json(state.route).push("/");
      }
    }

    return false;
  }
};

export const loginWithTokenHeavy = async (
  { effects, state },
  variables
) => {

  try {
    const { loginWithToken } = await effects.gql.mutations.loginWithTokenHeavy(
      variables
    );

    console.log('loginWithToken', loginWithToken)

    state.currentUser = loginWithToken.user;
    return true;
  } catch (e) {
    return false;
  }
};

export const setEventCart = async ({ state }, data) => {
  try {
    state.eventCart = data;
  } catch (e) {
    console.log(e, "setEventCart errors");
  }
};

export const loginWithTokenForBraintree = async (
  { effects, state, actions },
  variables
) => {
  if (!variables.token || !variables.userId) {
    return;
  }

  try {
    const { loginWithToken } = await effects.gql.mutations.loginWithToken(
      variables
    );

    state.currentUser = loginWithToken.user;
    state.isLoggedIn = true;
    state.authToken = variables.token;
    state.isAuthenticating = false;
    await actions.user.onCreateBraintreeToken();
    await actions.setStoredAuthToken();
    return true;
  } catch (e) {
    return false;
  }
};

export const setRoute = ({ state }, route) => {
  state.route = route;
};

/*
 *
 */
export const focusInput = ({ state }) => {
  state.errors = [];
};

/*
 *
 */
export const connectionChanged = ({ state }, connected) => {
  state.connected = connected;
};

export const setErrors = ({ state }, errors) => {
  state.errors = errors;
};

/*
 *
 */
export const signInClicked = ({ state }, redirectTo) => {
  state.signInModalOpen = true;
  state.redirectOnLogin = redirectTo || "";
};

/*
 *
 */
export const signOutClicked = async ({ state, effects, actions }) => {
  effects.analytics.track("Sign Out", {});
  state.workspace.openedWorkspaceItem = "files";
  if (state.live.isLive) {
    actions.live.internal.disconnect();
  }
  await effects.api.signout();
  effects.jwt.reset();
  state.currentUser = null;
  effects.browser.reload();
};

/*
 *
 */
export const signInButtonClicked = async ({ actions, state }, options) => {
  if (!options) {
    await actions.internal.signIn({
      useExtraScopes: false,
    });
    state.signInModalOpen = false;
    return;
  }
  await actions.internal.signIn(options);
  state.signInModalOpen = false;
};

/*
 *
 */
export const modalOpened = ({ state, effects }, props) => {
  effects.analytics.track("Open Modal", { modal: props.modal });
  state.currentModal = props.modal;
  if (props.modal === "preferences" && props.itemId) {
    state.preferences.itemId = props.itemId;
  } else {
    state.currentModalMessage = props.message || null;
  }
};

/*
 *
 */
export const modalClosed = ({ state }) => {
  state.currentModal = null;
};

/*
 *
 */
export const toggleSignInModal = ({ state }) => {
  state.signInModalOpen = !state.signInModalOpen;
};

/*
 *
 */
export const addNotification = ({ effects }, { message, type, timeAlive }) => {
  console.log(type, "type addNotification");
  effects.notificationToast.add({
    message,
    // status: effects.notificationToast.convertTypeToStatus(type),
    timeAlive: timeAlive * 1000,
  });
};

/*
 *
 */
export const removeNotification = ({ state }, id) => {
  const notificationToRemoveIndex = state.notifications.findIndex(
    (notification) => notification.id === id
  );

  state.notifications.splice(notificationToRemoveIndex, 1);
};

/*
 *
 */
export const notificationAdded = (
  { effects },
  { title, notificationType, timeAlive }
) => {
  console.log(notificationType, "notificationType notificationAdded");
  effects.notificationToast.add({
    message: title,
    // status: convertTypeToStatus(notificationType),
    timeAlive: timeAlive ? timeAlive * 1000 : undefined,
  });
};

/*
 *
 */
export const notificationRemoved = ({ state }, { id }) => {
  const { notifications } = state;
  const notificationToRemoveIndex = notifications.findIndex(
    (notification) => notification.id === id
  );

  state.notifications.splice(notificationToRemoveIndex, 1);
};

/*
 *
 */
export const track = ({ effects }, { name, data }) => {
  effects.analytics.track(name, data);
};

/*
 *
 */
export const signInGithubClicked = async ({ state, actions }) => {
  state.isLoadingGithub = true;
  await actions.internal.signIn({ useExtraScopes: true });
  state.isLoadingGithub = false;
};

/*
 *
 */
export const signOutGithubIntegration = async ({ state, effects }) => {
  if (state.currentUser?.integrations?.github) {
    await effects.api.signoutGithubIntegration();
    delete state.currentUser.integrations.github;
  }
};

export const setAddressRef = ({ state }, ref) => {
  state.addressRef = ref;
};

export const startLoading = ({ state }) => {
  state.isLoading = true;
};

export const finishLoading = ({ state }) => {
  state.isLoading = false;
};

export const verifyEmail = async ({ effects, actions }, data) => {
  try {
    return await effects.gql.mutations.verifyEmail(data);
  } catch (e) {
    console.log(e, "e");
    actions.alert.showError({ message: e.response.errors[0]["message"] });
  }
};

export const resendVerificationEmail = async ({ effects }, data) => {
  return await effects.gql.mutations.resendVerificationEmail(data);
};

/**
 * @param {queryReturnFields} should be an array of string like ['id', 'name']
 */
export const saveFormData = async (
  { effects },
  {
    mutation,
    params,
    where = null,
    queryReturnFields = [],
    waitForResult = true,
  }
) => {
  try {
    if (waitForResult) {
      const data = await effects.gql.mutations[mutation]({
        data: params,
        where,
      });
      return queryReturnFields.length > 0
        ? _.pick(data[mutation], queryReturnFields)
        : data[mutation];
    } else {
      return effects.gql.mutations[mutation]({ data: params, where });
    }
  } catch (ex) {
    console.log(ex);
  }
};

export const setCurrentVenture = ({ state }, venture) => {
  state.currentVenture = venture;
};

export const setCurrentCompany = ({ state }, company) => {
  state.currentCompany = company;
};

export const inviteUserAction = async (
  { actions },
  { route, user, noAlert }
) => {
  console.log(route, user, noAlert)
  if (route.query?.v) {
    actions.alert.showError({
      message: "You've been added to venture.",
    });
    await actions.venture.saveVenture({
      where: { id: route.query?.v },
      data: {
        users: {
          connect: [
            {
              id: user?.id,
            },
          ],
        },
      },
    });
  }
  if (route.query?.eco && !route.query?.ch) {
    if (!noAlert) {
      actions.alert.showError({
        message: "Please sign in to accept ecosystem invitation.",
      });
    }
    await actions.user.addUserToEcosystem({
      userId: user?.id,
      ecosystemId: route.query?.eco,
    });
  }
  if (route.query?.ch) {
    if (!noAlert) {
      actions.alert.showError({
        message: "Please sign in to accept cohort invitation.",
      });
    }
    await actions.user.addUserToCohort({
      userId: user?.id,
      cohortId: route.query?.ch,
    });
  }
  if (route.query?.eco && route.query?.isa) {
    if (!noAlert) {
      actions.alert.showError({
        message: "Please sign in to accept ecosystem admin invitation.",
      });
    }
    await actions.user.addAdminUserToEcoSystem({
      userId: user?.id,
      ecosystemId: route.query?.eco,
    });
  }
};
