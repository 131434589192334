import { get, isNil, map, keyBy, isEmpty } from "lodash";
import { json } from "overmind";

/*
 *
 */
export const getTotalUsers = async ({ state, effects }) => {
  const { users } = await effects.gql.queries.users();
  state.user.totalRecords = users ? users.length : 0;
};

/*
 *
 */
export const filterUsers = async ({ state, effects }, filter) => {
  try {
    state.user.isLoading = true;
    const result = await effects.gql.queries.users({ match: filter });
    // await actions.search.search({keywords: userSearchKeyword, userId: currentUser.id});

    console.log(result, "result");

    state.user.isLoading = false;
    state.user.filteredUsers = result.users;
  } catch (e) {
    // actions.alert.showError('Failed to update user', Title);
  }
};

export const updateUserProfile = async ({ state, effects }, data) => {
  console.log({ userId: state.currentUser.id, ...data });
  try {
    const { updateUser } = await effects.gql.mutations.updateUserProfile({
      userId: state.currentUser.id,
      ...data,
    });
    state.currentUser = updateUser.user;
    console.log("CurrentUser =>", updateUser.user);
    return updateUser.user;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const getPlaceFromCoordinates = async ({ state, effects }, data) => {
  const { getPlaceFromCoordinates } =
    await effects.gql.queries.getPlaceFromCoordinates(data);
  console.log(getPlaceFromCoordinates);
  state.user.currentLocation = getPlaceFromCoordinates;
  return getPlaceFromCoordinates;
};

export const setCurrentLocation = async ({ state }, data) => {
  state.user.currentLocation = data;
};
/*
 *
 */
export const getUsers = async ({ state, effects }, data) => {
  try {
    let options = {};
    if (isEmpty(data)) {
      options = {
        first: state.user.userPerPage,
        skip: (state.user.activePage - 1) * state.user.userPerPage,
      };
    } else {
      options = data;
      if (!data.first) options.first = state.user.userPerPage;
      if (!data.skip)
        options.skip = (state.user.activePage - 1) * state.user.userPerPage;
    }

    console.log(options, "getUsers options");

    const { users } = await effects.gql.queries.users(options);

    console.log(users, "getUsers results");
    if (data?.getValues) {
      return users;
    } else {
      state.user.users = keyBy(users, "id");
    }
  } catch (e) {
    console.log(e, "getUsers errors");
    // actions.alert.showError({ message: e.response.errors[0]['message'], title: 'Fetching Order' });
  }
};

/*
 *
 */
export const getUser = async ({ state, effects }, options) => {
  try {
    console.log(options, "getUser options");

    const { user } = await effects.gql.queries.user(options);

    console.log(user, "getUser results");

    state.user.users[user.id] = user;
    return json(user);
  } catch (e) {
    console.log(e, "getUser errors");
    // actions.alert.showError({ message: e.response.errors[0]['message'], title: 'Fetching Order' });
  }
};

/*
 *
 */
export const updateUser = async ({ state, effects, actions }, variables) => {
  console.log("saving user... ", variables);

  try {
    // Delete temporary site name
    if (variables && variables.siteName) delete variables.siteName;

    const { employer } = variables;

    // if (avatar?.base64) {
    //   Object.assign(variables, {
    //     avatar: avatar.base64,
    //     avatarType: avatar.contentType,
    //   })
    // } else {
    //   delete variables.avatar;
    // }

    // Add some location props
    // Check if from google places selector
    const placeId = get(employer, "details.place_id");
    const coords = get(employer, "details.geometry.location");

    if (employer?.id) {
      // when site is picker
      variables.site = { id: employer.id };
    } else if (!isNil(placeId)) {
      // if has google place id
      variables.googlePlacesId = placeId;
    } else if (!isNil(coords)) {
      // When selected from current location
      variables.gps = {
        lat: employer.details.coords.lat,
        lon: employer.details.coords.lng,
      };
    }
    if (employer?.address2) variables.address2 = employer.address2;
    // set timezone offset and timezone
    variables.timezoneOffset = new Date().getTimezoneOffset();
    variables.userId = state.currentUser.id;
    // variables.timezone = RNLocalize.getTimeZone();

    console.log(variables, "save user variables");

    const updatedUser = await effects.gql.mutations.updateUserProfile(
      variables
    );

    console.log(updatedUser.updateUser.user);

    state.currentUser = updatedUser.updateUser.user;
    return updatedUser;
  } catch (e) {
    console.log(e, Object.keys(e), "saveUser raw errors");

    if (e?.response?.errors) {
      console.log(map(e.response.errors, "message"), "saveUser errors");
      await actions.alert.showError({
        message: e.response.errors[0]["message"],
        title: "Profile Update",
      });
    }
  }
};

export const onCreateBraintreeToken = async ({ state, effects }) => {
  const { currentUser } = state;
  const { generateBraintreeClientToken } =
    await effects.gql.mutations.generateBraintreeClientToken({
      customerId: currentUser.braintreeCustomerId,
    });
  state.token = generateBraintreeClientToken;
  return generateBraintreeClientToken;
};

export const onAddPayment = async ({ state, effects }, paymentMethod) => {
  const { updateUser } = await effects.gql.mutations.updateUserProfile({
    paymentMethod,
    userId: state.currentUser.id,
  });
  console.log(updateUser);
  state.currentUser = updateUser.user;
  return updateUser.user;
};

export const saveUser = async ({ state, effects }, data) => {
  const { saveUser } = await effects.gql.mutations.saveUser(data);
  if (saveUser?.id === state.currentUser?.id) {
    state.currentUser = saveUser;
  }
  return saveUser;
};

export const getCurrentUser = async ({ state, effects }) => {
  const users = await effects.gql.queries.users({where: {id: state.currentUser?.id}});
  console.log(users, 'users')
  if (users?.[0]?.id === state.currentUser?.id) {
    state.currentUser = users?.[0];
  }
};

/*
 *
 */
export const onChangePage = async ({ state }, page) => {
  state.user.activePage = page;
};

/*
 *
 */
export const onUserAdded = ({ state }, data) => {
  state.user.push(data);
};

/*
 *
 */
export const requestSupport = async ({ effects, actions }, data) => {
  try {
    const { requestSupport } = await effects.gql.mutations.requestSupport(data);
    return !!requestSupport;
  } catch (e) {
    if (e?.response?.errors) {
      await actions.alert.showError({
        message: e.response.errors[0]["message"],
      });
    }
  }
};

export const passwordReset = async ({ effects }, data) => {
  try {
    return await effects.gql.mutations.passwordReset(data);
  } catch (e) {
    console.log(e);
  }
};

export const sendUsersInvitation = async ({ effects }, data) => {
  try {
    return await effects.gql.mutations.sendUsersInvitation(data);
  } catch (e) {
    console.log(e);
  }
};

export const deleteUserAddress = async ({ state, effects }, data) => {
  try {
    const { deleteUserAddress } = await effects.gql.mutations.deleteUserAddress(
      data
    );
    console.log(deleteUserAddress);
    state.currentUser = deleteUserAddress;
    return deleteUserAddress;
  } catch (e) {
    console.log(e);
  }
};

export const triggerPasswordReset = async ({ effects, actions }, data) => {
  try {
    return await effects.gql.mutations.triggerPasswordReset(data);
  } catch (e) {
    console.log(e);
  }
};

export const inviteContact = async ({ effects }, data) => {
  return await effects.gql.mutations.inviteContact(data);
};

export const uploadFileToS3 = async ({ effects, actions }, data) => {
  try {
    return await effects.gql.mutations.uploadFileToS3(data);
  } catch (e) {
    console.log(e);
    actions.alert.showError({
      message: "Something went wrong. Please try again later.",
      title: "Upload Media",
    });
  }
};

export const getClearbitCompany = async ({ effects, state }, data) => {
  try {
    const { getClearbitCompany } = await effects.gql.queries.getClearbitCompany(
      data
    );
    console.log(getClearbitCompany);
    state.user.companyDetail = getClearbitCompany;
    return getClearbitCompany;
  } catch (e) {
    console.log(e);
  }
};

export const getClearbitPerson = async ({ effects, state }, data) => {
  try {
    const { getClearbitPerson } = await effects.gql.queries.getClearbitPerson(
      data
    );
    state.user.userDetail = getClearbitPerson;
    return getClearbitPerson;
  } catch (e) {
    console.log(e);
  }
};

export const awsTempToken = async ({ effects, actions }, data) => {
  try {
    return await effects.gql.queries.awsTempToken(data);
  } catch (e) {
    console.log(e);
    actions.alert.showError({ message: e.response.errors[0]["message"] });
  }
};

export const deleteUser = async ({ effects }, data) => {
  try {
    return await effects.gql.mutations.deleteUser(data);
  } catch (e) {
    console.log(e);
  }
};

export const addUserToEcosystem = async ({ effects }, data) => {
  try {
    return await effects.gql.mutations.addUserToEcosystem(data);
  } catch (e) {
    console.log(e);
  }
};
export const addUserToCohort = async ({ effects }, data) => {
  try {
    return await effects.gql.mutations.addUserToCohort(data);
  } catch (e) {
    console.log(e);
  }
};
export const addAdminUserToEcoSystem = async ({ effects }, data) => {
  try {
    return await effects.gql.mutations.addAdminUserToEcoSystem(data);
  } catch (e) {
    console.log(e);
  }
};

export const findAllUsersByNumberOrEmail = async ({ effects }, data) => {
  try {
    return await effects.gql.queries.findAllUsersByNumberOrEmail(data);
  } catch (e) {
    console.log(e);
  }
};

export const inviteUserAction = async (
  { effects, actions, state },
  { route, user, noAlert }
) => {
  if (route.query?.v) {
    actions.alert.showError({
      message: "You've been added to venture.",
    });
    await actions.venture.saveVenture({
      where: { id: route.query?.v },
      data: {
        users: {
          connect: [
            {
              id: user?.id,
            },
          ],
        },
      },
    });
  }
  if (route.query?.eco && !route.query?.ch) {
    if (!noAlert) {
      actions.alert.showError({
        message: "Please sign in to accept ecosystem invitation.",
      });
    }
    await actions.user.addUserToEcosystem({
      userId: user?.id,
      ecosystemId: route.query?.eco,
    });
  }
  if (route.query?.ch) {
    if (!noAlert) {
      actions.alert.showError({
        message: "Please sign in to accept cohort invitation.",
      });
    }
    await actions.user.addUserToCohort({
      userId: user?.id,
      cohortId: route.query?.ch,
    });
  }
  if (route.query?.eco && route.query?.isa) {
    if (!noAlert) {
      actions.alert.showError({
        message: "Please sign in to accept ecosystem admin invitation.",
      });
    }
    await actions.user.addAdminUserToEcoSystem({
      userId: user?.id,
      ecosystemId: route.query?.eco,
    });
  }
};
