import gql from 'graphql-tag';
import { milestoneFragment } from './fragments';

/*
*
*/
export const saveMilestone = gql`
  mutation saveMilestone($data: MilestoneUpdateInput!, $where: MilestoneWhereUniqueInput) {
    saveMilestone(data: $data, where: $where) ${milestoneFragment}
  }
`;

/*
*
*/
export const deleteMilestone = gql`
  mutation deleteMilestone($where: MilestoneWhereUniqueInput) {
    deleteMilestone(where: $where) ${milestoneFragment}
  }
`;

export const calculateMilestone = gql`
  mutation calculateMilestone($ventureId: String!) {
    calculateMilestone(ventureId: $ventureId) {
      id
    }
  }
`