<template>
  <div class="w-full sm:w-80 h-full overflow-y-scroll">
    <div :class="`border-b flex justify-start items-center px-4 h-full w-full md:hidden bg-${navBgColor}`">
      <Icon name="MenuIcon" customClass="cursor-pointer" color="white" isOutline size="8"
        @click.stop="store.isShow = true" />
      <router-link to="/">
        <img src="../../../assets/images/dscvry_logo-white.svg"
          class="w-24 block absolute transform -translate-x-1/2 left-1/2 -mt-2" /></router-link>
    </div>
    <div v-click-outside.stop="onClose" :class="`h-full fixed top-0 flex flex-col transition ease-in-out transform -ml-96 w-64 xs:w-80 sm:w-80 z-50 ${store.isShow ? 'translate-x-96' : 'sm:translate-x-96'
      }`">
      <div :class="`w-full p-4 pb-0 bg-${navBgColor} border-r border-${profileTopBorderColor}`">
        <router-link to="/">
          <img src="../../../assets/images/dscvry_logo-white.svg" class="w-28 mt-2" />
        </router-link>
      </div>
      <DesktopNavigation class="w-full" :user="user" :logo="logo" :navigation="navigation"
        :ventureNavigations="ventureNavigations" :ecosystemNavigations="ecosystemNavigations"
        :nav-selected-item="navSelectedItem" :navIconColor="navIconColor" :navBgColor="navBgColor"
        :navTextColor="navTextColor" :navTextSelectionColor="navTextSelectionColor"
        :navIconSelectionColor="navIconSelectionColor" :navHoverBg="navHoverBg" :navHoverBgOpacity="navHoverBgOpacity"
        :navSelectionBgColor="navSelectionBgColor" :navHoverTextColor="navHoverTextColor"
        :profileTopBorderColor="profileTopBorderColor" :profileTitleTextColor="profileTitleTextColor"
        :profileSubtitleTextColor="profileSubtitleTextColor" :profileSectionCustomClass="profileSectionCustomClass"
        :navItemClicked="navItemClicked" :navCustomClass="navCustomClass" :navBadgeColor="badgeColor"
        :navBadgeSelectionColor="badgeSelectionColor" @onClickProfile="() => {
          $emit('onClickProfile');
          store.isShow = false;
        }
          " />
    </div>
  </div>
</template>

<script>
import { store } from "../../store.js";
import DesktopNavigation from "../SidebarNavigation/SimpleWithIconAndLogo/SimpleWithIconAndLogo.vue";
import Icon from "../../atoms/Icons/Icons.vue";
import Text from "../../atoms/Text/Text.vue";
import vClickOutside from "click-outside-vue3";
/**
 * - Use it to show a sidebar navigation
 */
export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    DesktopNavigation,
    Icon,
    Text,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    /**
     * Use it to show logo
     */
    logo: {
      type: Object,
      default: () => { },
    },
    /**
     * Use it to show navigation list
     */
    navigation: {
      type: Array,
      default: () => [],
    },
    ecosystemNavigations: {
      type: Array,
      default: () => [],
    },
    ventureNavigations: {
      type: Array,
      default: () => [],
    },
    /**
     * click event on clicking nav Item
     */
    navItemClicked: {
      type: Function,
      default: () => { },
    },
    /**
     * to give default selection for nav
     */
    navSelectedItem: {
      type: Object,
      default: () => { },
    },
    /**
     * Use it to assign bg color to navigation
     */
    navCustomClass: {
      type: String,
      default: "flex-1 px-2",
    },
    /**
     * use it for assigning background color for an item
     */
    navBgColor: {
      type: String,
      default: "white",
    },
    /**
     * use it for assigning background color for an item
     */
    navSelectionBgColor: {
      type: String,
      default: "gray-100",
    },
    /**
     * use it for assigning background color for an item on hover
     */
    navHoverBg: {
      type: String,
      default: "gray-50",
    },
    /**
     * use it for assigning background opacity for an item on hover
     */
    navHoverBgOpacity: {
      type: String,
      default: "",
    },
    /**
     * use it for assigning text color on hover
     */
    navHoverTextColor: {
      type: String,
      default: "white",
    },
    /**
     * use it for assigning icon color
     */
    navIconColor: {
      type: String,
      default: "gray-400",
    },
    /**
     * use it for assigning text color
     */
    navTextColor: {
      type: String,
      default: "gray-600",
    },
    /**
     * use it for assigning badge color
     */
    badgeColor: {
      type: String,
      default: "gray-200",
    },
    /**
     * use it for assigning icon color of current selection
     */
    navIconSelectionColor: {
      type: String,
      default: "gray-500",
    },
    /**
     * use it for assigning text color of current selection
     */
    navTextSelectionColor: {
      type: String,
      default: "gray-900",
    },
    /**
     * use it for assigning badge color of current selection
     */
    badgeSelectionColor: {
      type: String,
      default: "gray-50",
    },
    /**
     * user object for profile
     */
    user: {
      type: Object,
      default: null,
    },
    /**
     * color of top border for profile
     */
    profileTopBorderColor: {
      type: String,
      default: "gray-200",
    },
    /**
     * color of title for profile
     */
    profileTitleTextColor: {
      type: String,
      default: "gray-700",
    },
    /**
     * color of top border for profile
     */
    profileSubtitleTextColor: {
      type: String,
      default: "gray-500",
    },
    /**
     * custom class for profile section to set background color
     */
    profileSectionCustomClass: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      store,
      window,
    };
  },
  methods: {
    onClose() {
      if (store.isShow) {
        store.isShow = false;
      }
    }
  },
  computed: {
    selected: {
      // getter
      get() {
        return this.navSelectedItem;
      },
      // setter
      set(newValue) {
        // Note: we are using destructuring assignment syntax here.
        this.$emit("update:navSelectedItem", newValue);
      },
    },
  },
};
</script>
