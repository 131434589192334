import gql from 'graphql-tag';
import { actionStepFragment } from './fragments';

/*
*
*/
export const saveActionStep = gql`
  mutation saveActionStep($data: ActionStepUpdateInput!, $where: ActionStepWhereUniqueInput) {
    saveActionStep(data: $data, where: $where) ${actionStepFragment}
  }
`;

/*
*
*/
export const deleteActionStep = gql`
  mutation deleteActionStep($where: ActionStepWhereUniqueInput) {
    deleteActionStep(where: $where) ${actionStepFragment}
  }
`;
