import gql from 'graphql-tag';
import { actionStepFragment } from './fragments';

/*
*
*/
export const actionSteps = gql`
  query actionSteps($where: ActionStepWhereInput, $orderBy: ActionStepOrderByInput, $skip: Int, $after: String, $before: String, $first: Int, $last: Int) {
    actionSteps(where: $where, orderBy: $orderBy, skip: $skip, after: $after, before: $before, first: $first, last: $last) ${actionStepFragment}
  }
`;
