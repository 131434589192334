import gql from 'graphql-tag';
import { opportunityLinkFragment } from './fragments';

/*
*
*/
export const opportunityLinks = gql`
  query opportunityLinks($where: OpportunityLinkWhereInput, $orderBy: OpportunityLinkOrderByInput, $skip: Int, $after: String, $before: String, $first: Int, $last: Int) {
    opportunityLinks(where: $where, orderBy: $orderBy, skip: $skip, after: $after, before: $before, first: $first, last: $last) ${opportunityLinkFragment}
  }
`;
