import gql from 'graphql-tag';
import {opportunityFragment} from './fragments';

/*
*
*/
export const opportunities = gql`
  query opportunities($where: OpportunityWhereInput, $orderBy: OpportunityOrderByInput, $skip: Int, $after: String, $before: String, $first: Int, $last: Int) {
    opportunities(where: $where, orderBy: $orderBy, skip: $skip, after: $after, before: $before, first: $first, last: $last) ${opportunityFragment}
  }
`;

export const getSurveySummary = gql`
  query getSurveySummary($opportunityId: String $surveyId: String) {
    getSurveySummary(opportunityId: $opportunityId surveyId: $surveyId)
  }
`
