/*
 *
 */
export const getTotalInterviewScripts = async ({ state, effects }, data) => {
  const { interviewScripts } = await effects.gql.queries.interviewScripts(data);

  state.interviewScript.totalRecords = interviewScripts
    ? interviewScripts.length
    : 0;
};

/*
 *
 */
export const getInterviewScripts = async ({ state, effects }, data) => {
  console.log("action getInterviewScripts...");
  try {
    let options = {};
    if (!data) {
      options = {
        first: state.interviewScript.interviewScriptPerPage,
        skip:
          (state.interviewScript.activePage - 1) *
          state.interviewScript.interviewScriptPerPage,
      };
    } else {
      if (data && data.all) options = {};
      else {
        options = data;
        if (!data.first)
          options.first = state.interviewScript.interviewScriptPerPage;
        if (!data.skip)
          options.skip =
            (state.interviewScript.activePage - 1) *
            state.interviewScript.interviewScriptPerPage;
      }
    }
    //
    const { interviewScripts } = await effects.gql.queries.interviewScripts(
      options
    );
    if (data && data.getValues) return interviewScripts;
    else state.interviewScript.interviewScripts = interviewScripts;
  } catch (e) {
    console.log(e, "getInterviewScripts errors");
  }
};

/*
 *
 */
export const saveInterviewScript = async ({ effects, actions }, data) => {
  try {
    return await effects.gql.mutations.saveInterviewScript(data);
  } catch (e) {
    console.log(e, "saveInterviewScript errors");
    actions.alert.showError({
      message:
        e.response && e.response.errors && e.response.errors.length
          ? e.response.errors[0]["message"]
          : "Error",
      title: "Saving InterviewScript",
    });
  }
};

/*
 *
 */
export const onChangePage = async ({ state }, page) => {
  state.interviewScript.activePage = page;
};

/*
 *
 */
export const onInterviewScriptAdded = ({ state }, data) => {
  state.interviewScript.push(data);
};

/*
 *
 */
export const addUserToInterviewScript = async ({ effects, actions }, data) => {
  return await effects.gql.mutations.addUserToInterviewScript(data);
};

/*
 *
 */
export const updateInterviewScriptUser = async ({ effects, actions }, data) => {
  return await effects.gql.mutations.updateInterviewScriptUser(data);
};

/*
 *
 */
export const removeUserFromInterviewScript = async (
  { effects, actions },
  data
) => {
  return await effects.gql.mutations.removeUserFromInterviewScript(data);
};

export const updateDefaultInterviewScriptMilestone = async (
  { effects, actions },
  data
) => {
  return await effects.gql.mutations.updateDefaultInterviewScriptMilestone(
    data
  );
};
