import gql from "graphql-tag";
import { ventureContactDetailFragment, ventureContactFragment, ventureDetailFragment, ventureFragment, ventureInterviewFragment, ventureListFragment, ventureOpportunityFragment, ventureSettingFragment, ventureSurveyDataFragment, ventureTargetCustomerDetailFragment, ventureTargetCustomerFragment } from "./fragments";

export const ventures = gql`
  query ventures($where: VentureWhereInput, $orderBy: VentureOrderByInput, $skip: Int, $after: String, $before: String, $first: Int, $last: Int) {
    ventures(where: $where, orderBy: $orderBy, skip: $skip, after: $after, before: $before, first: $first, last: $last) ${ventureFragment}
  }
`;

export const venturesList = gql`
  query ventures($where: VentureWhereInput, $orderBy: VentureOrderByInput, $skip: Int, $after: String, $before: String, $first: Int, $last: Int) {
    ventures(where: $where, orderBy: $orderBy, skip: $skip, after: $after, before: $before, first: $first, last: $last) ${ventureListFragment}
  }
`;

export const ventureDetail = gql`
  query ventures($where: VentureWhereInput, $orderBy: VentureOrderByInput, $skip: Int, $after: String, $before: String, $first: Int, $last: Int) {
    ventures(where: $where, orderBy: $orderBy, skip: $skip, after: $after, before: $before, first: $first, last: $last) ${ventureDetailFragment}
  }
`;

export const ventureContacts = gql`
  query ventures($where: VentureWhereInput, $orderBy: VentureOrderByInput, $skip: Int, $after: String, $before: String, $first: Int, $last: Int) {
    ventures(where: $where, orderBy: $orderBy, skip: $skip, after: $after, before: $before, first: $first, last: $last) ${ventureContactFragment}
  }
`;

export const ventureInterviews = gql`
  query ventures($where: VentureWhereInput, $orderBy: VentureOrderByInput, $skip: Int, $after: String, $before: String, $first: Int, $last: Int) {
    ventures(where: $where, orderBy: $orderBy, skip: $skip, after: $after, before: $before, first: $first, last: $last) ${ventureInterviewFragment}
  }
`;

export const ventureOpportunities = gql`
  query ventures($where: VentureWhereInput, $orderBy: VentureOrderByInput, $skip: Int, $after: String, $before: String, $first: Int, $last: Int) {
    ventures(where: $where, orderBy: $orderBy, skip: $skip, after: $after, before: $before, first: $first, last: $last) ${ventureOpportunityFragment}
  }
`;

export const ventureSettings = gql`
  query ventures($where: VentureWhereInput, $orderBy: VentureOrderByInput, $skip: Int, $after: String, $before: String, $first: Int, $last: Int) {
    ventures(where: $where, orderBy: $orderBy, skip: $skip, after: $after, before: $before, first: $first, last: $last) ${ventureSettingFragment}
  }
`;

export const ventureSurveyData = gql`
  query ventures($where: VentureWhereInput, $orderBy: VentureOrderByInput, $skip: Int, $after: String, $before: String, $first: Int, $last: Int) {
    ventures(where: $where, orderBy: $orderBy, skip: $skip, after: $after, before: $before, first: $first, last: $last) ${ventureSurveyDataFragment}
  }
`;

export const ventureTargetCustomers = gql`
  query ventures($where: VentureWhereInput, $orderBy: VentureOrderByInput, $skip: Int, $after: String, $before: String, $first: Int, $last: Int) {
    ventures(where: $where, orderBy: $orderBy, skip: $skip, after: $after, before: $before, first: $first, last: $last) ${ventureTargetCustomerFragment}
  }
`;

export const ventureContactDetail = gql`
  query ventures($where: VentureWhereInput, $orderBy: VentureOrderByInput, $skip: Int, $after: String, $before: String, $first: Int, $last: Int) {
    ventures(where: $where, orderBy: $orderBy, skip: $skip, after: $after, before: $before, first: $first, last: $last) ${ventureContactDetailFragment}
  }
`;

export const ventureTargetCustomerDetail = gql`
  query ventures($where: VentureWhereInput, $orderBy: VentureOrderByInput, $skip: Int, $after: String, $before: String, $first: Int, $last: Int) {
    ventures(where: $where, orderBy: $orderBy, skip: $skip, after: $after, before: $before, first: $first, last: $last) ${ventureTargetCustomerDetailFragment}
  }
`;

export const venturesTotalList = gql`
  query ventures(
    $where: VentureWhereInput
    $orderBy: VentureOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    ventures(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      id
      name
      targetCustomerUsers {
        id
        isArchived
        contacts {
          id
          isArchived
        }
      }
      resources {
        id
      }
      opportunities {
        id
        isArchived
      }
      contacts {
        id
        isArchived
        interviews {
          id
          isArchived
          scheduledDateTime
        }
      }
      interviews {
        id
        isArchived
      }
    }
  }
`;


export const venturesForLength = gql`
  query ventures(
    $where: VentureWhereInput
    $orderBy: VentureOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    ventures(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      id
    }
  }
`;