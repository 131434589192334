import {keyBy, isEmpty} from 'lodash';

/*
*
*/
export const getTotalSurveyOpportunitys = async ({state, effects}) => {
  const {surveyOpportunities} = await effects.gql.queries.surveyOpportunities();

  state.surveyOpportunity.totalRecords = surveyOpportunities ? surveyOpportunities.length : 0;
};

/*
*
*/
export const getSurveyOpportunitys = async ({state, effects}, data) => {
  console.log('action getSurveyOpportunitys...');

  try {

    let options = {};

    if (isEmpty(data)) {
      options = {
        first: state.surveyOpportunity.surveyOpportunityPerPage,
        skip: (state.surveyOpportunity.activePage - 1) * state.surveyOpportunity.surveyOpportunityPerPage,
      };
    } else {
      options = data;
      if (!data.first) {
        options.first = state.surveyOpportunity.surveyOpportunityPerPage;
      }
      if (!data.skip) {
        options.skip = (state.surveyOpportunity.activePage - 1) * state.surveyOpportunity.surveyOpportunityPerPage;
      }
    }

    console.log(options, 'getSurveyOpportunitys options');

    const {surveyOpportunities} = await effects.gql.queries.surveyOpportunities(options);

    console.log(surveyOpportunities, 'getSurveyOpportunitys results');

    state.surveyOpportunity.surveyOpportunities = keyBy(surveyOpportunities, 'id');

  } catch (e) {
    console.log(e, 'getSurveyOpportunitys errors');
    // actions.alert.showError({ message: e.response.errors[0]['message'], title: 'Fetching Order' });
  }
};

/*
*
*/
export const saveSurveyOpportunity = async ({effects}, data) => {
  return await effects.gql.mutations.saveSurveyOpportunity(data);
};

export const getSurveyOpportunityById = async ({effects}, data) => {
  const {surveyOpportunities} = await effects.gql.queries.surveyOpportunities(data);
  return surveyOpportunities[0]
};

/*
*
*/
export const onChangePage = async ({state}, page) => {
  state.surveyOpportunity.activePage = page;
};

/*
*
*/
export const onSurveyOpportunityAdded = ({state}, data) => {
  state.surveyOpportunity.push(data);
};
