<template>
  <div class="flex flex-col flex-1 min-h-0" :class="`bg-${navBgColor}`">
    <div :class="`flex flex-col flex-grow border-r border-${profileTopBorderColor} pt-0 pb-4 overflow-y-auto`">
      <div class="flex items-center flex-shrink-0 px-4">
        <Image :customClass="`${!isShow && `hidden`}`" :src="logo.src" width="auto" :height="8" :alt="logo.title" />
      </div>
      <div class="flex flex-col flex-grow mt-3">
        <VerticalNavigation v-model:selected-item="selected" :navigation="navigation"
          :ecosystemNavigations="ecosystemNavigations" :ventureNavigations="ventureNavigations"
          :item-clicked="navItemClicked" :nav-custom-class="navCustomClass" :selection-bg-color="navSelectionBgColor"
          :hover-bg="navHoverBg" :text-color="navTextColor" :text-selection-color="navTextSelectionColor"
          :icon-color="navIconColor" :icon-selection-color="navIconSelectionColor" :bg-color="navBgColor"
          :hover-bg-opacity="navHoverBgOpacity" :hover-text-color="navHoverTextColor" :badgeColor="navBadgeColor"
          :badgeSelectionColor="navBadgeSelectionColor" :venture="state.currentVenture" />
      </div>
    </div>
    <div v-if="user"
      :class="`flex-shrink-0 flex border-t border-r border-${profileTopBorderColor} ${profileSectionCustomClass} p-4`">
      <a class="flex-shrink-0 block w-full group" @click="$emit('onClickProfile')">
        <AvatarText :src="user.avatar" :username="user.name" :title="user.name" :subtitle="user.role"
          :title-color="profileTitleTextColor" :subtitle-color="profileSubtitleTextColor" />
      </a>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import Image from "../../../atoms/Image/Image.vue";
import VerticalNavigation from "../../VerticalNavigation/WithIconsAndBadges/WithIconsAndBadges.vue";
import AvatarText from "../../../molecules/Avatar/AvatarText.vue";
/**
 * - Use it to show a sidebar navigation
 */
export default {
  components: {
    Image,
    VerticalNavigation,
    AvatarText,
  },
  props: {
    /**
     * Use it to show logo
     */
    logo: {
      type: Object,
      default: () => { },
    },
    /**
     * Use it to show navigation list
     */
    navigation: {
      type: Array,
      default: () => [],
    },
    ventureNavigations: {
      type: Array,
      default: () => [],
    },
    ecosystemNavigations: {
      type: Array,
      default: () => [],
    },
    /**
     * click event on clicking nav Item
     */
    navItemClicked: {
      type: Function,
      default: () => { },
    },
    /**
     * to give default selection for nav
     */
    navSelectedItem: {
      type: Object,
      default: () => { },
    },
    /**
     * Use it to assign bg color to navigation
     */
    navCustomClass: {
      type: String,
      default: "flex-1 px-2",
    },
    /**
     * use it for assigning background color for an item
     */
    navBgColor: {
      type: String,
      default: "white",
    },
    /**
     * use it for assigning background color for an item
     */
    navSelectionBgColor: {
      type: String,
      default: "gray-100",
    },
    /**
     * use it for assigning background color for an item on hover
     */
    navHoverBg: {
      type: String,
      default: "gray-50",
    },
    /**
     * use it for assigning background opacity for an item on hover
     */
    navHoverBgOpacity: {
      type: String,
      default: "",
    },
    /**
     * use it for assigning text color on hover
     */
    navHoverTextColor: {
      type: String,
      default: "gray-900",
    },
    /**
     * use it for assigning icon color
     */
    navIconColor: {
      type: String,
      default: "gray-400",
    },
    /**
     * use it for assigning text color
     */
    navTextColor: {
      type: String,
      default: "gray-600",
    },
    /**
     * use it for assigning badge color
     */
    navBadgeColor: {
      type: String,
      default: "gray-200",
    },
    /**
     * use it for assigning icon color of current selection
     */
    navIconSelectionColor: {
      type: String,
      default: "gray-500",
    },
    /**
     * use it for assigning text color of current selection
     */
    navTextSelectionColor: {
      type: String,
      default: "gray-900",
    },
    /**
     * use it for assigning badge color of current selection
     */
    navBadgeSelectionColor: {
      type: String,
      default: "gray-50",
    },
    /**
     * user object for profile
     */
    user: {
      type: Object,
      default: null,
    },
    /**
     * color of top border for profile
     */
    profileTopBorderColor: {
      type: String,
      default: "gray-200",
    },
    /**
     * color of title for profile
     */
    profileTitleTextColor: {
      type: String,
      default: "gray-700",
    },
    /**
     * color of top border for profile
     */
    profileSubtitleTextColor: {
      type: String,
      default: "gray-500",
    },
    /**
     * custom class for profile section to set background color
     */
    profileSectionCustomClass: {
      type: String,
      default: "",
    },
    isShow: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const selected = computed({
      get() {
        return props.navSelectedItem;
      },
      set(newValue) {
        emit("update:navSelectedItem", newValue);
      },
    });
    return {
      selected,
    };
  },
  mounted() {
    console.log(this.navBgColor)
  }
};
</script>
