import gql from 'graphql-tag';
import { programFragment } from './fragments';

/*
*
*/
export const saveProgram = gql`
  mutation saveProgram($data: ProgramUpdateInput!, $where: ProgramWhereUniqueInput) {
    saveProgram(data: $data, where: $where) ${programFragment}
  }
`;

/*
*
*/
export const deleteProgram = gql`
  mutation deleteProgram($where: ProgramWhereUniqueInput) {
    deleteProgram(where: $where) ${programFragment}
  }
`;
