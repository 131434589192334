import gql from 'graphql-tag';
import { opportunityEconomyFragment } from './fragments';

/*
*
*/
export const saveOpportunityEconomy = gql`
  mutation saveOpportunityEconomy($data: OpportunityEconomyUpdateInput!, $where: OpportunityEconomyWhereUniqueInput) {
    saveOpportunityEconomy(data: $data, where: $where) ${opportunityEconomyFragment}
  }
`;

/*
*
*/
export const deleteOpportunityEconomy = gql`
  mutation deleteOpportunityEconomy($where: OpportunityEconomyWhereUniqueInput) {
    deleteOpportunityEconomy(where: $where) ${opportunityEconomyFragment}
  }
`;
