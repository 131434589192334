import {keyBy, isEmpty} from 'lodash';

/*
*
*/
export const getTotalSchools = async ({state, effects}) => {
  const {schools} = await effects.gql.queries.schools();

  state.school.totalRecords = schools ? schools.length : 0;
};

/*
*
*/
export const getSchools = async ({state, effects}, data) => {
  console.log('action getSchools...');

  try {

    let options = {};

    if (isEmpty(data)) {
      options = {
        first: state.school.schoolPerPage,
        skip: (state.school.activePage - 1) * state.school.schoolPerPage,
      };
    } else {
      options = data;
      if (!data.first) {
        options.first = state.school.schoolPerPage;
      }
      if (!data.skip) {
        options.skip = (state.school.activePage - 1) * state.school.schoolPerPage;
      }
    }

    console.log(options, 'getSchools options');

    const {schools} = await effects.gql.queries.schools(options);

    console.log(schools, 'getSchools results');

    state.school.schools = keyBy(schools, 'id');

  } catch (e) {
    console.log(e, 'getSchools errors');
    // actions.alert.showError({ message: e.response.errors[0]['message'], title: 'Fetching Order' });
  }
};

/*
*
*/
export const saveSchool = async ({effects}, data) => {
  return await effects.gql.mutations.saveSchool(data);
};

export const getSchoolById = async ({effects}, data) => {
  const {schools} = await effects.gql.queries.schools(data);
  return schools[0]
};

/*
*
*/
export const onChangePage = async ({state}, page) => {
  state.school.activePage = page;
};

/*
*
*/
export const onSchoolAdded = ({state}, data) => {
  state.school.push(data);
};
