<template>
  <div style="z-index: 200000 !important; transition: all .8s ease;" class="fixed top-4 w-full sm:w-96" :class="visible ? 'slide-in' : 'slide-out'">
    <WithDismissButton @onClose="() => hideAlert()" :type="type" :message="message" :title="title" :icon="type === 'success' ? 'CheckCircleIcon' : 'XCircleIcon'"/>
  </div>
</template>

<script>

import WithDismissButton from "../organisms/Alert/WithDismissButton/WithDismissButton.vue";

export default {
  components: {WithDismissButton},
  props: ['type', 'message', 'visible', 'title'],
  methods: {
    hideAlert: function () {
      this.actions.alert.hide();
    }
  },
  watch: {
    visible: function () {
      setTimeout(() => this.hideAlert(), 3000)
    }
  }
}
</script>
<style scoped>
.slide-in {
	right: 24px !important;

}
.slide-out {
	right: -400px !important;
}

@media (max-width: 746px) {
	.slide-in {
		right: 0px !important;
	}
	.slide-out {
		right: -100% !important;
	}
}

</style>
