<template>
	<div :class="`rounded-md bg-${isSuccess ? 'green': 'rose'}-50 p-4`">
		<div class="flex">
			<div class="flex-shrink-0">
				<Icon :name="icon" size="5" :color="`${isSuccess ? 'green': 'rose'}-500`"/>
			</div>
			<div class="ml-3">
        <p v-if="title" :class="`text-sm font-semibold text-${isSuccess ? 'green': 'rose'}-800`">{{title}}</p>
				<p :class="`text-sm font-medium text-${isSuccess ? 'green': 'rose'}-800`">{{message}}</p>
			</div>
			<div class="ml-auto pl-3">
				<div class="-mx-1.5 -my-1.5">
					<button type="button" @click="$emit('onClose')"
									:class="`inline-flex bg-${isSuccess ? 'green': 'rose'}-50 rounded-md p-1.5 text-${isSuccess ? 'green': 'rose'}-500 hover:bg-${isSuccess ? 'green': 'rose'}-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-${isSuccess ? 'green': 'rose'}-50 focus:ring-${isSuccess ? 'green': 'rose'}-600`">
						<Icon :name="'XIcon'" size="5" :color="`${isSuccess ? 'green': 'rose'}-500`"/>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Icon from '../../../atoms/Icons/Icons.vue'
import Button from '../../../atoms/Button/Button.vue'
/**
 * - Use it when show alert with actions
 */
export default {
  components: {
    Button,
    Icon,
  },
  props: {
    /**
     * Use to show message
     */
    message: {
      type: String,
    },
    /**
     * Use to show title
     */
     title: {
      type: String,
    },
		/**
     * Use to show icon
     */
    icon: {
      type: String,
    },
    /**
     * Use it for alert status
     */
    type: {
      type: String,
      default: 'success'
    }
  },
  computed: {
    isSuccess: function () {
      return this.type === 'success'
    }
  }
};
</script>
