import { keyBy, isEmpty } from "lodash";
import { Settings } from "../../../../settings";
import axios from "axios";

/*
 *
 */
export const getTotalVentures = async ({ state, effects }) => {
  console.log(state.currentUser?.id);
  if (state.currentUser?.id) {
    const { ventures } = await effects.gql.queries.venturesForLength({
      where: {
        users_some: { id: state.currentUser?.id },
        isArchived_not: true,
      },
    });
    state.venture.totalRecords = ventures ? ventures.length : 0;
  }
};

export const getVentures = async ({ state, effects }, data) => {
  console.log("action getVentures...");

  try {
    let options = {};

    if (isEmpty(data)) {
      options = {
        first: state.venture.venturePerPage,
        skip: (state.venture.activePage - 1) * state.venture.venturePerPage,
      };
    } else {
      options = data;
      if (data.all) {
      } else {
        if (!data.first) {
          options.first = state.venture.venturePerPage;
        }
        if (!data.skip) {
          options.skip =
            (state.venture.activePage - 1) * state.venture.venturePerPage;
        }
      }
    }

    options.where = { ...options.where, isArchived_not: true };

    console.log(options, "getVentures options");

    let items = null;
    if (data?.query) {
      const res = await effects.gql.queries[data?.query](options);
      items = res?.ventures;
    } else {
      const res = await effects.gql.queries.ventures(options);
      items = res?.ventures;
    }

    console.log(items, "getVentures results");
    if (data?.getValues) {
      return items;
    } else {
      state.venture.ventures = keyBy(items, "id");
    }
  } catch (e) {
    console.log(e, "getVentures errors");
  }
};

export const getVentureById = async ({ effects }, id) => {
  const { ventures } = await effects.gql.queries.venturesTotalList({
    where: { id },
  });
  return ventures[0];
};
/*
 *
 */
export const saveVenture = async ({ effects }, data) => {
  return await effects.gql.mutations.saveVenture(data);
};

export const onChangePerPage = ({ state }, perPage) => {
  state.venture.venturePerPage = perPage;
};
export const onChangePage = ({ state }, page) => {
  state.venture.activePage = page;
};
export const purgeVenture = async ({ effects }, data) => {
  return await effects.gql.mutations.purgeVenture(data);
};
export const addVentureTo = async ({ effects }, data) => {
  return await effects.gql.mutations.addVentureTo(data);
};
export const removeVentureFrom = async ({ effects }, data) => {
  return await effects.gql.mutations.removeVentureFrom(data);
};
