export const state = {
    surveyOpportunities: {},
    isLoading: false,
    currentProductId: null,
    surveyOpportunityPerPage: 10,
    totalRecords: 10,
    activePage: 1,
    addOns: [],
    surveyOpportunityList: surveyOpportunityNamespace =>
      Object.values(surveyOpportunityNamespace.surveyOpportunities)
        .sort((a, b) => {
          if (a.createdAt > b.createdAt) {
            return 1
          } else if (a.createdAt < b.createdAt) {
            return -1
          }

          return 0
        })
        .slice(0, surveyOpportunityNamespace.surveyOpportunityPerPage)
  }
