import gql from "graphql-tag";

export const targetCustomerFragment = gql`
  {
    id
    name
    status
    type
    tags {
      id
      name
      type
      category
      isHidden
    }
    targetCustomerUsers {
      id
      name
      venture {
        id
      }
      targetCustomer {
        id
      }
      researchPrompt
      estMarketSize
      estAnnualIncome
      researchSource
      interviewScripts {
        id
      }
      surveys {
        id
      }
      opportunities {
        id
        isArchived
      }
      contacts {
        id
        isArchived
      }
    }
  }
`;
