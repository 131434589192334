import gql from "graphql-tag";

export const economyFragment = gql`
  {
    id
    name
    priority
    problem
    improvement
  }
`;
