/*
*
*/
export const getTotalActionSteps = async ({state, effects}, data) => {
  const {actionSteps} = await effects.gql.queries.actionSteps(data)

  state.actionStep.totalRecords = actionSteps ? actionSteps.length : 0
}

/*
*
*/
export const getActionSteps = async ({state, effects}, data) => {
  console.log('action getActionSteps...')
  try {
    let options = {}
    if (!data) {
      options = {
        first: state.actionStep.actionStepPerPage,
        skip: (state.actionStep.activePage - 1) * state.actionStep.actionStepPerPage
      }
    } else {
      if (data && data.all) options = {}
      else {
        options = data;
        if (!data.first) options.first = state.actionStep.actionStepPerPage;
        if (!data.skip) options.skip = (state.actionStep.activePage - 1) * state.actionStep.actionStepPerPage;
      }
    }
    //
    const {actionSteps} = await effects.gql.queries.actionSteps(options)
    if (data && data.getValues) return actionSteps
    else state.actionStep.actionSteps = actionSteps
  } catch (e) {
    console.log(e, 'getActionSteps errors');
  }
}

/*
*
*/
export const saveActionStep = async ({effects, actions}, data) => {
  try {
    return await effects.gql.mutations.saveActionStep(data)
  } catch (e) {
    console.log(e, 'saveActionStep errors')
    actions.alert.showError({
      message: e.response && e.response.errors && e.response.errors.length ? e.response.errors[0]['message'] : 'Error',
      title: 'Saving ActionStep'
    });
  }
}

/*
*
*/
export const onChangePage = async ({state}, page) => {
  state.actionStep.activePage = page
}

/*
*
*/
export const onActionStepAdded = ({state}, data) => {
  state.actionStep.push(data)
}
