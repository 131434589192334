/*
 *
 */
export const getTotalMilestones = async ({ state, effects }, data) => {
  let options = {};
  if (data && data.options) options = data.options;
  const { milestones } = await effects.gql.queries.milestones(options);

  state.milestone.totalRecords = milestones ? milestones.length : 0;
};

/*
 *
 */
export const getMilestones = async ({ state, effects }, data) => {
  console.log("action getMilestones...");
  try {
    let options = {};
    if (!data) {
      options = {
        first: state.milestone.milestonePerPage,
        skip:
          (state.milestone.activePage - 1) * state.milestone.milestonePerPage,
      };
    } else {
      if (data && data.all) options = {};
      else {
        options = data;
        if (!data.first) options.first = state.milestone.milestonePerPage;
        if (!data.skip)
          options.skip =
            (state.milestone.activePage - 1) * state.milestone.milestonePerPage;
      }
    }
    //
    const { milestones } = await effects.gql.queries.milestones(options);
    if (data && data.getValues) return milestones;
    else state.milestone.milestones = milestones;
  } catch (e) {
    console.log(e, "getMilestones errors");
  }
};

/*
 *
 */
export const saveMilestone = async ({ effects, actions }, data) => {
  try {
    return await effects.gql.mutations.saveMilestone(data);
  } catch (e) {
    console.log(e, "saveMilestone errors");
    actions.alert.showError({
      message:
        e.response && e.response.errors && e.response.errors.length
          ? e.response.errors[0]["message"]
          : "Error",
      title: "Saving Milestone",
    });
  }
};

export const calculateMilestone = async ({ effects, actions }, data) => {
  try {
    return await effects.gql.mutations.calculateMilestone(data);
  } catch (e) {
    console.log(e, "calculateMilestone errors");
  }
};

/*
 *
 */
export const onChangePage = async ({ state }, page) => {
  state.milestone.activePage = page;
};

/*
 *
 */
export const onMilestoneAdded = ({ state }, data) => {
  state.milestone.push(data);
};

export const getMilestoneByName = async ({ effects, actions }, data) => {
  try {
    const resp = await effects.gql.queries.milestones(data);
    return resp;
  } catch (e) {
    actions.alert.showError({
      message:
        e.response && e.response.errors && e.response.errors.length
          ? e.response.errors[0]["message"]
          : "Error",
      title: "Get Milestone By Name",
    });
  }
};
