import { createApp, provide, h } from "vue";
import App from "./App.vue";
import { OvermindPlugin } from "@/store";
import * as Sentry from "@sentry/vue";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import { BrowserTracing } from "@sentry/tracing";
import { Settings } from "../settings";
import Vue3VideoPlayer from "@cloudgeek/vue3-video-player";
import "@cloudgeek/vue3-video-player/dist/vue3-video-player.css";
import VueGoogleMaps from "@fawmi/vue-google-maps";
const axios = require("axios");
import errorToJSON from "error-to-json";
// import LoomTheme from './components/themes/default';
// import { registerSharedComponents } from '@/plugins/register'

// import winston from "winston";
// import "setimmediate"; // this is needed for Winston Logger
// import AppitryTransport from "./custom-logs";
import "./console-listener";
import router from "./router";
import { DefaultApolloClient } from "@vue/apollo-composable";
import { apolloClient } from "./apollo-client";

// CSS
import "./main.css";
import VueSocialSharing from "vue-social-sharing";

//
/** Globally register layout component */
import Default from "./layouts/Default";

import "animate.css";

// registerSharedComponents();

const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient);
  },
  render: () => h(App),
});

// Stream the errors
if (Settings.environment == "dev") {
  app.config.errorHandler = function (err) {
    // console.log(typeof err, errorToJSON(err), "err post");
    const sendParams = {};
    sendParams.headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      publicApi: true,
    };

    axios.post(
      Settings.gqlServer.buildUrl,
      {
        query: `mutation writeStreamLog($log: Json, $errors: Json $saveToDb: Boolean) {
            writeStreamLog(log: $log errors: $errors  saveToDb: $saveToDb)
        }`,
        variables: {
          errors: errorToJSON(err),
        },
      },
      sendParams
    );
  };

  app.config.warnHandler = function (warn) {
    console.warn(`[CUSTOM WARNING] ${warn}`);
  };

  // WINSTON
  // winston.createLogger({
  //   level: Settings.winston.level,
  //   // format: combine(timestamp(), json()),
  //   format: winston.format.json(),
  //   defaultMeta: { service: Settings.groupDomain },
  //   transports: [
  //     // new AppitryTransport(),
  //     new winston.transports.Console({ level: "error" }), // { handleExceptions: true }
  //     new winston.transports.Console({ level: "warn" }), // { handleExceptions: true }
  //     new winston.transports.Console({ level: "info" }), // { handleExceptions: true }
  //     new winston.transports.Console({ level: "log" }), // { handleExceptions: true }
  //     // new winston.transports.Http({ host: Settings.gqlServer.url }),
  //   ],
  // });
}

// TODO - Sentry x OpenTelemetry - https://docs.sentry.io/platforms/node/performance/instrumentation/opentelemetry/
// TODO - Sentry Release Automation - https://docs.sentry.io/product/releases/setup/ - https://docs.sentry.io/product/releases/setup/release-automation/
// TODO - Research https://www.npmjs.com/package/vuejs-logger
// TODO - Research Winston and Morgan - https://www.npmjs.com/package/morgan
// LOGGING - https://betterstack.com/community/guides/logging/how-to-install-setup-and-use-winston-and-morgan-to-log-node-js-applications/#configuring-transports-in-winston

if (
  (Settings.environment == "pre" || Settings.environment == "production") &&
  Settings.sentry &&
  Settings.sentry.dsn &&
  Settings.sentry.dsn.length > 0
) {
  Sentry.init({
    app,
    dsn: Settings.sentry.dsn,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ["localhost", Settings.mainDomain, /^\//],
        // beforeNavigate: (ctx) => {
        //   return {
        //     ...ctx,
        //     name: fxn(ctx.name, window.location) => {}
        //   };
        // },
      }),
      // new CaptureConsoleIntegration({
      //   levels: Settings.sentry.logLevel,
      // }),
      new Sentry.Replay(),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    logErrors: true,
  });
}

app.use(router);
app.use(OvermindPlugin);
app.use(Vue3VideoPlayer);
app.use(VueSocialSharing, {
  networks: {
    instagram: "https://www.instagram.com",
    youtube: "https://www.youtube.com",
    tiktok: "https://www.tiktok.com",
  },
});
app.use(VueGoogleMaps, {
  load: {
    key: Settings.googleMapKey,
    libraries: "places",
  },
});
// app.use(VueTailwind, { theme: LoomTheme })
app.component("default-layout", Default);
app.mount("#app");
