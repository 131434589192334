import gql from "graphql-tag";

export const cohortFragment = gql`
  {
    id
    name
    ventures {
      id
      name
      users {
        id
        firstName
        lastName
        email
        createdAt
      }
      targetCustomerUsers {
        id
        isArchived
      }
      contacts {
        id
        isArchived
      }
      interviews {
        id
        isArchived
      }
      opportunities {
        id
        isArchived
        name
        source
        frequency
        avgFrequency
        frequencyUnit
        actionDonePercentage
        desireText
        outOfMarketPercentage
        lowMarketPercentage
        midMarketPercentage
        hiMarketPercentage
        experience
        magnitudeScore
        desireScore
        adjustedMarket
        type
        feeling
        parentOpportunityLinks {
          id
        }
        problems {
          id
          economy {
            id
            name
          }
        }
        surveyOpportunities {
          id
          responses {
            id
            isArchived
          }
        }
        childOpportunityLinks {
          id
        }
        interviews {
          id
        }
        targetCustomerUser {
          id
          name
        }
        actionStep {
          id
          name
        }
      }
      milestones {
        id
        name
        tagLabel
      }
    }
    program {
      id
      name
      type
    }
    users {
      id
      firstName
      lastName
      email
      groups {
        id
        name
      }
      createdAt
    }
    startDate
    endDate
    createdBy {
      id
      firstName
      lastName
      email
    }
  }
`;
