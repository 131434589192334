import gql from 'graphql-tag';
import { cohortFragment } from './fragments';

/*
*
*/
export const cohorts = gql`
  query cohorts($where: CohortWhereInput, $orderBy: CohortOrderByInput, $skip: Int, $after: String, $before: String, $first: Int, $last: Int) {
    cohorts(where: $where, orderBy: $orderBy, skip: $skip, after: $after, before: $before, first: $first, last: $last) ${cohortFragment}
  }
`;
