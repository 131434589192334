import gql from 'graphql-tag';
import { tagFragment } from './fragments';

/*
*
*/
export const saveTag = gql`
  mutation saveTag($data: TagUpdateInput!, $where: TagWhereUniqueInput) {
    saveTag(data: $data, where: $where) ${tagFragment}
  }
`;

/*
*
*/
export const deleteTag = gql`
  mutation deleteTag($where: TagWhereUniqueInput) {
    deleteTag(where: $where) ${tagFragment}
  }
`;
