import { keyBy, isEmpty } from "lodash";

/*
 *
 */
export const getTotalResources = async ({ state, effects }) => {
  const { resources } = await effects.gql.queries.resources();

  state.resource.totalRecords = resources ? resources.length : 0;
  console.log(state.resource.totalRecords, "totalRecords");
};
export const getResources = async ({ state, effects }, data) => {
  console.log("action getResources...");

  try {
    let options = {};

    if (isEmpty(data)) {
      options = {
        first: state.resource.resourcePerPage,
        skip: (state.resource.activePage - 1) * state.resource.resourcePerPage,
      };
    } else {
      options = data;
      if (!data.first) {
        options.first = state.resource.resourcePerPage;
      }
      if (!data.skip) {
        options.skip =
          (state.resource.activePage - 1) * state.resource.resourcePerPage;
      }
    }

    console.log(options, "getResources options");

    const { resources } = await effects.gql.queries.resources(options);

    console.log(resources, "getResources results");
    if (data?.getValues) {
      return resources;
    } else {
      state.resource.resources = keyBy(resources, "id");
    }
  } catch (e) {
    console.log(e, "getResources errors");
  }
};

/*
 *
 */
export const saveResource = async ({ effects }, data) => {
  return await effects.gql.mutations.saveResource(data);
};

export const onChangePerPage = ({ state }, perPage) => {
  state.resource.resourcePerPage = perPage;
};
export const onChangePage = ({ state }, page) => {
  state.resource.activePage = page;
};
