<template>
    <div
        v-if="
            $route.meta.requiresAuth &&
            !exceptSidebarRoutes.includes($route.name)
        "
        class="flex flex-col sm:flex-row bg-gray-50 h-screen"
    >
        <div class="h-16 sm:h-full fixed top-0 sm:relative w-full sm:w-80 z-50">
            <SideNavigationBar
                :title="sideNavigationBar.title"
                :user="{
                    avatar: state.currentUser?.avatar,
                    name:
                        state.currentUser?.firstName +
                        ' ' +
                        state.currentUser?.lastName,
                    role: get(state.currentUser, 'groups[0].name', ''),
                }"
                :logo="sideNavigationBar.logo"
                :navigation="getNavigations()"
                :ventureNavigations="
                    isEcosystem()
                        ? []
                        : [
                              {
                                  id: 21,
                                  name: 'Dashboard',
                                  icon: 'HomeIcon',
                                  href: `/venture/${state.currentVenture?.id}/detail`,
                              },
                              {
                                  id: 22,
                                  name: 'Target Customers',
                                  count: `${
                                      state.currentVenture?.targetCustomerUsers?.filter(
                                          (t) => !t?.isArchived
                                      )?.length || 0
                                  }`,
                                  icon: 'BookOpenIcon',
                                  href: `/venture/${state.currentVenture?.id}/target-customers`,
                              },
                              {
                                  id: 23,
                                  name: 'Contacts',
                                  icon: 'UsersIcon',
                                  count: `${
                                      state.currentVenture?.contacts?.filter(
                                          (o) => !o?.isArchived
                                      )?.length || 0
                                  }`,
                                  href: `/venture/${state.currentVenture?.id}/contacts`,
                              },
                              {
                                  id: 24,
                                  name: 'Opportunities',
                                  count: `${
                                      state.currentVenture?.opportunities?.filter(
                                          (o) => !o?.isArchived
                                      )?.length || 0
                                  }`,
                                  icon: 'ExclamationIcon',
                                  href: `/venture/${state.currentVenture?.id}/opportunities`,
                              },
                              {
                                  id: 25,
                                  name: 'Interviews',
                                  icon: 'BookmarkIcon',
                                  count: `${
                                      state.currentVenture?.contacts
                                          ?.filter((c) =>
                                              !c?.isArchived && c?.interviews?.find(
                                                  (interview) =>
                                                      interview?.scheduledDateTime
                                              )
                                          )?.length || 0
                                  }`,
                                  href: `/venture/${state.currentVenture?.id}/interviews`,
                              },
                              {
                                  id: 26,
                                  name: 'Survey Data',
                                  count: `${
                                      state.currentVenture?.surveyData
                                          ?.length || 0
                                  }`,
                                  icon: 'ChartBarIcon',
                                  href: `/venture/${state.currentVenture?.id}/survey-data`,
                              },
                              //   {
                              //       id: 27,
                              //       name: 'Resources',
                              //       count: state.resource.totalRecords,
                              //       icon: 'LinkIcon',
                              //       href: `/venture/${state.currentVenture?.id}/resources`,
                              //   },
                              {
                                  id: 28,
                                  name: 'Settings',
                                  icon: 'CogIcon',
                                  href: `/venture/${state.currentVenture?.id}/settings`,
                              },
                          ]
                "
                :ecosystem-navigations="
                    isEcosystem()
                        ? []
                        : [
                              {
                                  id: 31,
                                  name: state.currentCompany?.name,
                                  count: null,
                                  icon: 'HomeIcon',
                                  href: `/ecosystem/${state.currentCompany?.id}/detail`,
                              },
                              {
                                  id: 32,
                                  name: 'Programs',
                                  count:
                                      state.currentCompany?.programs?.length ||
                                      0,
                                  icon: 'BookOpenIcon',
                                  href: `/ecosystem/${state.currentCompany?.id}/programs`,
                              },
                              {
                                  id: 33,
                                  name: 'Ventures',
                                  count: getTeams(),
                                  icon: 'LightBulbIcon',
                                  href: `/ecosystem/${state.currentCompany?.id}/venture-teams`,
                              },
                              {
                                  id: 34,
                                  name: 'People',
                                  count: this.totalPeople(),
                                  icon: 'UsersIcon',
                                  href: `/ecosystem/${state.currentCompany?.id}/people`,
                              },
                              {
                                  id: 35,
                                  name: 'Opportunities',
                                  icon: 'ExclamationIcon',
                                  count: getOpportunities(),
                                  href: `/ecosystem/${state.currentCompany?.id}/opportunities`,
                              },
                              //   {
                              //       id: 36,
                              //       name: 'Resources',
                              //       count: state.resource.totalRecords,
                              //       icon: 'LinkIcon',
                              //       href: `/ecosystem/${state.currentCompany?.id}/resources`,
                              //   },
                              {
                                  id: 37,
                                  name: 'Settings',
                                  icon: 'CogIcon',
                                  href: `/ecosystem/${state.currentCompany?.id}/settings`,
                              },
                          ]
                "
                :navSelectedItem="selectedItem"
                :navIconColor="sideNavigationBar.navIconColor"
                :navBgColor="sideNavigationBar.navBgColor"
                :navTextColor="sideNavigationBar.navTextColor"
                :navTextSelectionColor="sideNavigationBar.navTextSelectionColor"
                :navIconSelectionColor="sideNavigationBar.navIconSelectionColor"
                :navHoverBg="sideNavigationBar.navHoverBg"
                :navHoverBgOpacity="sideNavigationBar.navHoverBgOpacity"
                :navSelectionBgColor="sideNavigationBar.navSelectionBgColor"
                :navHoverTextColor="sideNavigationBar.navHoverTextColor"
                :profileTopBorderColor="sideNavigationBar.profileTopBorderColor"
                :profileTitleTextColor="sideNavigationBar.profileTitleTextColor"
                :profileSubtitleTextColor="
                    sideNavigationBar.profileSubtitleTextColor
                "
                :profileSectionCustomClass="
                    sideNavigationBar.profileSectionCustomClass
                "
                :badgeColor="sideNavigationBar.badgeColor"
                :nav-item-clicked="
                    (item) => {
                        $router.push(item.href);
                    }
                "
                @onClickProfile="() => $router.push('/settings')"
                :badgeSelectionColor="sideNavigationBar.badgeSelectionColor"
            />
        </div>
        <div id="container" />
        <div
            class="w-full flex flex-col flex-1 overflow-y-scroll pt-20 sm:pt-0 mt-0 pb-40 sm:pb-0 sm:mt-0 px-4 sm:px-0"
        >
            <slot />
        </div>
    </div>
    <div v-else class="w-screen">
        <slot />
    </div>
    <Alert
        :type="state.alert.type"
        :message="state.alert.message"
        :visible="state.alert.visible"
        :title="state.alert.title"
    />
</template>

<script>
import { c } from "@/components/constants.js";
import Alert from "@/components/common/Alert";
import SideNavigationBar from "@/components/organisms/SideNavigationBar/SideNavigationBar";
import { get, upperFirst } from "lodash";
import moment from "moment";

export default {
    name: "Default",
    components: {
        Alert,
        SideNavigationBar,
    },
    data() {
        return {
            sideNavigationBar: c.sideNavigationBar,
            selectedItem:
                c.sideNavigationBar.navigation.find(
                    (n) => n.name === this.$route.name
                ) || {},
            get,
            exceptSidebarRoutes: [
                "Verify Email Step",
                "Verify Email",
                "Demographic Information",
                "Interest And Activity",
                "Register Company",
                "Setup Payment",
                "Invite People",
            ],
        };
    },
    watch: {
        $route: function () {
            this.selectedItem =
                c.sideNavigationBar.navigation.find(
                    (n) => n.name === this.$route.name
                ) || {};
            setTimeout(
                () => document.getElementById("container")?.scrollIntoView(),
                300
            );
            this.getVentureData();
        },
    },
    computed: {},
    methods: {
        getOpportunities() {
            let items = 0;
            this.state.currentCompany?.programs?.map((p) =>
                p?.cohorts?.map((c) =>
                    c?.ventures?.map((v) => {
                        items += v?.opportunities?.filter(
                            (o) =>
                                !o?.isArchived &&
                                o?.surveyOpportunities?.find(
                                    (s) => s?.responses?.length > 0
                                )
                        )?.length;
                    })
                )
            );
            return items;
        },
        getTeams() {
            if (this.state.currentCompany?.id) {
                let ventures = [];
                ventures = this.state.currentCompany?.ventures;
                this.state.currentCompany?.programs?.map((p) =>
                    p?.cohorts?.map(
                        (c) =>
                            (ventures = [
                                ...ventures,
                                ...c?.ventures?.filter(
                                    (v) =>
                                        !ventures?.find(
                                            (vv) => vv?.id === v?.id
                                        )
                                ),
                            ])
                    )
                );
                return ventures?.length;
            }
        },
        totalPeople() {
            if (this.state.currentCompany?.id) {
                const people = [];
                this.state.currentCompany?.ventures?.map((v) =>
                    v?.users?.map((u) => {
                        if (!people?.find((p) => p?.id?.id === u?.id)) {
                            people?.push({
                                Name:
                                    upperFirst(u?.firstName) +
                                    " " +
                                    upperFirst(u?.lastName),
                                Email: u?.email,
                                Team: upperFirst(v?.name),
                                "Date Joined": moment(u?.createdAt).format(
                                    "M/D/YY"
                                ),
                                id: u,
                            });
                        }
                    })
                );
                this.state.currentCompany?.programs?.map((p) =>
                    p?.cohorts?.map((c) =>
                        c?.ventures?.map((v) =>
                            v?.users?.map((u) => {
                                if (!people?.find((p) => p?.id?.id === u?.id)) {
                                    people?.push({
                                        Name:
                                            upperFirst(u?.firstName) +
                                            " " +
                                            upperFirst(u?.lastName),
                                        Email: u?.email,
                                        Team: upperFirst(v?.name),
                                        "Date Joined": moment(
                                            u?.createdAt
                                        ).format("M/D/YY"),
                                        id: u,
                                    });
                                }
                            })
                        )
                    )
                );
                return people?.length;
            }
        },
        isEcosystem() {
            return this.state.currentUser?.groups?.find(
                (g) => g?.name === "ecosystem-owner"
            );
        },
        isEcosystemUser() {
            return (
                this.state.currentUser?.userGroups?.find(
                    (g) => g?.group?.name === "ecosystem-user"
                ) &&
                !this.state.currentUser?.userGroups?.find(
                    (g) => g?.group?.name === "ecosystem-admin-user"
                )
            );
        },
        isEcosystemAdmin() {
            return this.state.currentUser?.userGroups?.find(
                (g) => g?.group?.name === "ecosystem-admin-user"
            );
        },
        async getVentureData() {
            if (!this.$route.params?.ventureId) {
                this.actions.setCurrentVenture(null);
                
            } else if (this.$route.params?.ventureId && this.state.currentVenture?.id !== this.$route.params?.ventureId){
                const venture = await this.actions.venture.getVentureById(
                    this.$route.params?.ventureId
                );
                let surveyData = [];
                for (
                    let i = 0;
                    i <
                    venture.targetCustomerUsers?.filter((t) => !t?.isArchived)
                        ?.length;
                    i++
                ) {
                    const targetCustomer = venture.targetCustomerUsers?.filter(
                        (t) => !t?.isArchived
                    )[i];
                    const data = await this.actions.userSurvey.getUserSurveys({
                        where: {
                            isArchived_not: true,
                            survey: {
                                targetCustomerUser: { id: targetCustomer?.id },
                            },
                        },
                        getValues: true,
                    });
                    console.log(data, "data");
                    surveyData = [...surveyData, ...data];
                }
                venture.surveyData = surveyData;
                this.actions.setCurrentVenture(venture);
            }
        },
        getNavigations() {
            if (this.isEcosystem()) {
                return [
                    {
                        id: 31,
                        name: this.state.currentCompany?.name,
                        count: null,
                        icon: "HomeIcon",
                        href: `/ecosystem/${this.state.currentCompany?.id}/detail`,
                    },
                    {
                        id: 32,
                        name: "Programs",
                        count: this.state.currentCompany?.programs?.length || 0,
                        icon: "BookOpenIcon",
                        href: `/ecosystem/${this.state.currentCompany?.id}/programs`,
                    },
                    {
                        id: 33,
                        name: "Ventures",
                        count: this.state.currentCompany?.ventures?.length || 0,
                        icon: "LightBulbIcon",
                        href: `/ecosystem/${this.state.currentCompany?.id}/venture-teams`,
                    },
                    {
                        id: 34,
                        name: "People",
                        count: this.totalPeople(),
                        icon: "UsersIcon",
                        href: `/ecosystem/${this.state.currentCompany?.id}/people`,
                    },
                    {
                        id: 35,
                        name: "Opportunities",
                        count: this.getOpportunities(),
                        icon: "ExclamationIcon",
                        href: `/ecosystem/${this.state.currentCompany?.id}/opportunities`,
                    },
                    // {
                    //     id: 36,
                    //     name: "Resources",
                    //     count: this.state.resource.totalRecords,
                    //     icon: "LinkIcon",
                    //     href: `/ecosystem/${this.state.currentCompany?.id}/resources`,
                    // },
                    {
                        id: 37,
                        name: "Settings",
                        icon: "CogIcon",
                        href: `/ecosystem/${this.state.currentCompany?.id}/settings`,
                    },
                ];
            } else {
                const items = [
                    { id: 1, name: "Dashboard", icon: "HomeIcon", href: "/" },
                    {
                        id: 2,
                        name: "Ideas / Ventures",
                        count: this.state.venture.totalRecords,
                        icon: "BookOpenIcon",
                        href: "/ventures",
                    },
                ];
                if (this.isEcosystemAdmin()) {
                    this.state.currentUser?.companies?.map((c, i) => {
                        items.push({
                            id: 3 + i,
                            name: c.name,
                            icon: "AcademicCapIcon",
                            href: this.isEcosystemUser()
                                ? null
                                : `/ecosystem/${c?.id}/detail`,
                        });
                    });
                }

                // items.push({
                //     id: 100,
                //     name: "Resources",
                //     icon: "LinkIcon",
                //     href: "/resources",
                // });
                return items;
            }
        },
    },
    mounted() {
        this.getVentureData();
    },
    async mounted() {
        // await this.actions.resource.getTotalResources();
        await this.actions.venture.getTotalVentures();
    },
    created() {},
};
</script>

<style scoped>
.content {
    padding: 0 15px;
    min-height: 84vh;
}
</style>
