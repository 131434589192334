import gql from 'graphql-tag';
import { ventureFragment } from "@/store/effects/gql/venture/fragments";

export const resourceFragment = gql`{
    id
		name
    type
    sortOrder
    milestone{id}
    ventures${ventureFragment}
    directLink{id source}
    externalLink{id}
    files{id}
    summary
    body  
}`
