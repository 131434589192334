import gql from 'graphql-tag';
import {targetCustomerFragment} from './fragments';

export const targetCustomers = gql`
  query targetCustomers($where: TargetCustomerWhereInput, $orderBy: TargetCustomerOrderByInput, $skip: Int, $after: String, $before: String, $first: Int, $last: Int) {
    targetCustomers(where: $where, orderBy: $orderBy, skip: $skip, after: $after, before: $before, first: $first, last: $last) ${targetCustomerFragment}
  }
`;

export const getDemographicDataCharts = gql`
  query getDemographicDataCharts($targetCustomerUserId: String) {
    getDemographicDataCharts(targetCustomerUserId: $targetCustomerUserId)
  }
`
