import gql from "graphql-tag";

export const userSurveyFragment = gql`
  {
    id
    isArchived
    user {
      id
      firstName
      lastName
      fullName
      email
    }
    survey {
      id
      name
      targetCustomerUser {
        id
        targetCustomer {
          id
          name
        }
      }
    }
    profile {
      id
      targetCustomerUser {
        id
        targetCustomer {
          id
          name
        }
      }
    }
    answers {
      id
      answer
      question {
        id
        question
        category
      }
      answers {
        id
        answer
        metadata
      }
    }
    assessment {
      id
      name
    }
    opportunity {
      id
      name
      actionStep {
        id
        name
      }
      avgFrequency
      frequencyUnit
    }
    metadata
    isCompleted
    isExpired
    magnitude
    frequency
    frequencyText
    desire
    experience
    isActionDone
    annualizedMagnitude
    createdAt
  }
`;
