/*
 *
 */
export const getTotalCohorts = async ({ state, effects }, data) => {
  const { cohorts } = await effects.gql.queries.cohorts(data);

  state.cohort.totalRecords = cohorts ? cohorts.length : 0;
};

/*
 *
 */
export const getCohorts = async ({ state, effects }, data) => {
  console.log("action getCohorts...");
  try {
    let options = {};
    if (!data) {
      options = {
        first: state.cohort.cohortPerPage,
        skip: (state.cohort.activePage - 1) * state.cohort.cohortPerPage,
      };
    } else {
      if (data && data.all) options = {};
      else {
        options = data;
        if (!data.first) options.first = state.cohort.cohortPerPage;
        if (!data.skip)
          options.skip =
            (state.cohort.activePage - 1) * state.cohort.cohortPerPage;
      }
    }
    //
    const { cohorts } = await effects.gql.queries.cohorts(options);
    if (data && data.getValues) return cohorts;
    else state.cohort.cohorts = cohorts;
  } catch (e) {
    console.log(e, "getCohorts errors");
  }
};

/*
 *
 */
export const saveCohort = async ({ effects, actions }, data) => {
  try {
    return await effects.gql.mutations.saveCohort(data);
  } catch (e) {
    console.log(e, "saveCohort errors");
    actions.alert.showError({
      message:
        e.response && e.response.errors && e.response.errors.length
          ? e.response.errors[0]["message"]
          : "Error",
      title: "Saving Cohort",
    });
  }
};

/*
 *
 */
export const onChangePage = async ({ state }, page) => {
  state.cohort.activePage = page;
};

/*
 *
 */
export const onCohortAdded = ({ state }, data) => {
  state.cohort.push(data);
};

/*
 *
 */
export const addUserToCohort = async ({ effects, actions }, data) => {
  return await effects.gql.mutations.addUserToCohort(data);
};

/*
 *
 */
export const updateCohortUser = async ({ effects, actions }, data) => {
  return await effects.gql.mutations.updateCohortUser(data);
};

/*
 *
 */
export const removeUserFromCohort = async ({ effects, actions }, data) => {
  return await effects.gql.mutations.removeUserFromCohort(data);
};
