import { createOvermind } from "overmind";
import { merge, namespaced } from "overmind/config";
import { createPlugin } from "overmind-vue";
import { onInitialize } from "./onInitialize";
import { state } from "./state";
import * as effects from "./effects";
import * as actions from "./actions";

// custom
import * as user from "./namespaces/user";
import * as notification from "./namespaces/notification";
import * as google from "./namespaces/google";
import * as site from "./namespaces/site";
import * as event from "./namespaces/event";
import * as cart from "./namespaces/cart";
import * as product from "./namespaces/product";
import * as venture from "./namespaces/venture";
import * as targetCustomer from "./namespaces/targetCustomer";
import * as dateSlider from "./namespaces/dateSlider";
import * as search from "./namespaces/search";
import * as alert from "./namespaces/alert";
import * as order from "./namespaces/order";
import * as paymentMethod from "./namespaces/paymentMethod";
import * as wallet from "./namespaces/wallet";
import * as company from "./namespaces/company";
import * as group from "./namespaces/group";
import * as school from "./namespaces/school";
import * as financialApplication from "./namespaces/financialApplication";
import * as tag from "./namespaces/tag";
import * as resource from "./namespaces/resource";
import * as program from "./namespaces/program";
import * as profile from "./namespaces/profile";
import * as opportunity from "./namespaces/opportunity";
import * as cohort from "./namespaces/cohort";
import * as actionStep from "./namespaces/actionStep";
import * as opportunityEconomy from "./namespaces/opportunityEconomy";
import * as surveyOpportunity from "./namespaces/surveyOpportunity";
import * as survey from "./namespaces/survey";
import * as userSurvey from "./namespaces/userSurvey";
import * as milestone from './namespaces/milestone'
import * as economy from './namespaces/economy'
import * as interviewScript from './namespaces/interviewScript'

export const config = merge(
  {
    state,
    effects,
    actions,
    onInitialize,
  },
  namespaced({
    alert,
    user,
    notification,
    google,
    site,
    cart,
    event,
    product,
    venture,
    dateSlider,
    search,
    order,
    paymentMethod,
    wallet,
    company,
    group,
    school,
    financialApplication,
    tag,
    targetCustomer,
    resource,
    program,
    profile,
    opportunity,
    cohort,
    actionStep,
    opportunityEconomy,
    surveyOpportunity,
    survey,
    userSurvey,
    milestone,
    economy,
    interviewScript
  })
);

const overmind = createOvermind(config, {
  devtools: false, // defaults to localhost:3031
});

export const OvermindPlugin = createPlugin(overmind);
