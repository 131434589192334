import gql from "graphql-tag";

export const opportunityFragment = gql`
  {
    id
    name
    venture {
      id
      name
    }
    targetCustomerUser {
      id
      name
      targetCustomer {
        id
        name
      }
    }
    desireText
    desireScore
    experience
    contact {
      id
    }
    outOfMarket
    inMarket
    adjustedMarket
    earlyAdopters
    earlyAdopterSize
    actionStep {
      id
      name
    }
    type
    frequency
    avgFrequency
    frequencyUnit
    magnitudeScore

    problems {
      id
      name
      type
      economy {
        id
        name
        problem
        improvement
      }
    }
    problemContext
    parentOpportunityLinks {
      id
      parent {
        id
        name
        earlyAdopterSize
        earlyAdopters
      }
    }
    childOpportunityLinks {
      id
      child {
        id
        name
        earlyAdopterSize
        earlyAdopters
      }
    }
    interviews {
      id
      isArchived
      contact {
        id
        user {
          firstName
          lastName
        }
      }
      name
      scheduledDateTime
      opportunities {
        id
        isArchived
      }
    }
    surveyOpportunities {
      id
      name
      opportunity {
        id
        name
        experience
        magnitudeScore
        frequency
        avgFrequency
        frequencyUnit
        desireScore
      }
      responses {
        id
        user {
          id
          firstName
          lastName
          email
          fullName
        }
        survey {
          id
          name
          description
          status
        }
        answers {
          id
          answer
          question {
            id
            question
            category
          }
          answers {
            id
            answer
          }
        }
        assessment {
          id
        }
        opportunity {
          id
          name
          magnitudeScore
          frequency
          avgFrequency
          isActionDone
          frequencyUnit
        }
        metadata
        isCompleted
        isExpired
        magnitude
        isActionDone
        frequencyText
        frequency
        experience
        annualizedMagnitude
        desire
        createdAt
      }
    }
    magnitudeScore
    adjustedMarket
  }
`;
