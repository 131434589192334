/*
*
*/
export const getTotalTags = async ({state, effects}, data) => {
  const {tags} = await effects.gql.queries.tags(data)

  state.tag.totalRecords = tags ? tags.length : 0
}

/*
*
*/
export const getTags = async ({state, effects}, data) => {
  console.log('action getTags...')
  try {
    let options = {}
    if (!data) {
      options = {
        first: state.tag.tagPerPage,
        skip: (state.tag.activePage - 1) * state.tag.tagPerPage
      }
    } else {
      if (data && data.all) options = {where: data.where || {}}
      else {
        options = data;
        if (!data.first) options.first = state.tag.tagPerPage;
        if (!data.skip) options.skip = (state.tag.activePage - 1) * state.tag.tagPerPage;
      }
    }
    //
    const {tags} = await effects.gql.queries.tags(options)
    console.log('tags: ', tags);
    if (data && data.getValues) return tags
    else state.tag.tags = tags
  } catch (e) {
    console.log(e, 'getTags errors');
  }
}

/*
*
*/
export const saveTag = async ({effects, actions}, data) => {
  try {
    return await effects.gql.mutations.saveTag(data)
  } catch (e) {
    console.log(e, 'saveTag errors')
    actions.alert.showError({
      message: e.response && e.response.errors && e.response.errors.length ? e.response.errors[0]['message'] : 'Error',
      title: 'Saving Tag'
    });
  }
}

/*
*
*/
export const onChangePage = async ({state}, page) => {
  state.tag.activePage = page
}

/*
*
*/
export const onTagAdded = ({state}, data) => {
  state.tag.push(data)
}
