import gql from "graphql-tag";
import { cohortFragment } from "./fragments";
import { userFragment } from "@/store/effects/gql/user/fragments";

/*
 *
 */
export const saveCohort = gql`
  mutation saveCohort($data: CohortUpdateInput!, $where: CohortWhereUniqueInput) {
    saveCohort(data: $data, where: $where) ${cohortFragment}
  }
`;

/*
 *
 */
export const deleteCohort = gql`
  mutation deleteCohort($where: CohortWhereUniqueInput) {
    deleteCohort(where: $where) ${cohortFragment}
  }
`;

/*
 *
 */
export const updateCohortUser = gql`
  mutation updateCohortUser($cohortId: String!, $email: String!, $userId: String!, $groupId: String) {
    updateCohortUser(cohortId: $cohortId, email: $email, userId: $userId, groupId: $groupId) ${userFragment}
  }
`;

/*
 *
 */
export const removeUserFromCohort = gql`
  mutation removeUserFromCohort($cohortId: String!, $userId: String!) {
    removeUserFromCohort(cohortId: $cohortId, userId: $userId)
  }
`;
