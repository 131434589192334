import gql from "graphql-tag";

export const profileFragment = gql`
  {
    id
    openToTalk
    metadata
    relationship
    surveys {
      id
      name
      surveyOpportunities {
        id
        responses {
          id
          profile {
            id
          }
          user {
            id
            firstName
            lastName
            fullName
          }
        }
      }
      publicUrl
    }
    targetCustomerUser {
      id
      name
      researchPrompt
      surveys {
        id
        name
        surveyOpportunities {
          id
          responses {
            id
            profile {
              id
            }
            user {
              id
              firstName
              lastName
              fullName
            }
          }
        }
        publicUrl
      }
      targetCustomer {
        id
        tags {
          id
          name
          type
          category
          isHidden
        }
        name
      }
      opportunities {
        id
        name
        isArchived
        actionStep {
          id
          name
        }
        avgFrequency
        frequencyUnit
        frequency
        parentOpportunityLinks {
          id
        }
        childOpportunityLinks {
          id
        }
      }
    }

    user {
      id
      firstName
      lastName
      email
      emails {
        id
        address
      }
      phones {
        id
        number
      }
      tags {
        id
        name
        type
        category
        status
        isHidden
      }
      socialHandles {
        id
        platform
        handle
      }
      company {
        id
        name
        username
      }
    }
    relationship
    sentEmails {
      id
    }
    targetCustomerUser {
      id
      name
      researchPrompt
      interviewScripts {
        id
        introScript
        questionScript
        outroScript
        name
      }
      interviews {
        id
        isArchived
        scheduledDateTime
        contact {
          id
        }
      }
      targetCustomer {
        id
        tags {
          id
          name
          type
          category
          isHidden
        }
        name
      }
      emailTemplates {
        id
        name
        type
        subject
        body
        metadata
      }
    }
    status
    interviews {
      id
      isArchived
      scheduledDateTime
      name
      description
      venture {
        id
      }
      recordingUrl
      timezone
      opportunities {
        id
        name
        isArchived
      }
      interviewScript {
        id
      }
    }
    notes
  }
`;
