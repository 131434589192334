/*
 *
 */
export const getTotalPrograms = async ({ state, effects }, data) => {
  const { programs } = await effects.gql.queries.programs(data);

  state.program.totalRecords = programs ? programs.length : 0;
};

/*
 *
 */
export const getPrograms = async ({ state, effects }, data) => {
  console.log("action getPrograms...");
  try {
    let options = {};
    if (!data) {
      options = {
        first: state.program.programPerPage,
        skip: (state.program.activePage - 1) * state.program.programPerPage,
      };
    } else {
      if (data && data.all) options = {};
      else {
        options = data;
        if (!data.first) options.first = state.program.programPerPage;
        if (!data.skip)
          options.skip =
            (state.program.activePage - 1) * state.program.programPerPage;
      }
    }
    //
    const { programs } = await effects.gql.queries.programs(options);
    if (data && data.getValues) return programs;
    else state.program.programs = programs;
  } catch (e) {
    console.log(e, "getPrograms errors");
  }
};

/*
 *
 */
export const saveProgram = async ({ effects, actions }, data) => {
  try {
    return await effects.gql.mutations.saveProgram(data);
  } catch (e) {
    console.log(e, "saveProgram errors");
    actions.alert.showError({
      message:
        e.response && e.response.errors && e.response.errors.length
          ? e.response.errors[0]["message"]
          : "Error",
      title: "Saving Program",
    });
  }
};

export const deleteProgram = async ({ effects }, data) => {
  try {
    return await effects.gql.mutations.deleteProgram(data);
  } catch (e) {
    console.log(e, "saveProgram errors");
  }
};

/*
 *
 */
export const onChangePage = async ({ state }, page) => {
  state.program.activePage = page;
};

/*
 *
 */
export const onProgramAdded = ({ state }, data) => {
  state.program.push(data);
};
