/*
*
*/
export const getTotalProfiles = async ({state, effects}, data) => {
  const {profiles} = await effects.gql.queries.profiles(data)

  state.profile.totalRecords = profiles ? profiles.length : 0
}

/*
*
*/
export const getProfiles = async ({state, effects}, data) => {
  console.log('action getProfiles...')
  try {
    let options = {}
    if (!data) {
      options = {
        first: state.profile.profilePerPage,
        skip: (state.profile.activePage - 1) * state.profile.profilePerPage
      }
    } else {
      if (data && data.all) options = {}
      else {
        options = data;
        if (!data.first) options.first = state.profile.profilePerPage;
        if (!data.skip) options.skip = (state.profile.activePage - 1) * state.profile.profilePerPage;
      }
    }
    //
    const {profiles} = await effects.gql.queries.profiles(options)
    if (data && data.getValues) return profiles
    else state.profile.profiles = profiles
  } catch (e) {
    console.log(e, 'getProfiles errors');
  }
}

/*
*
*/
export const saveProfile = async ({effects, actions}, data) => {
  try {
    return await effects.gql.mutations.saveProfile(data)
  } catch (e) {
    console.log(e, 'saveProfile errors')
    actions.alert.showError({
      message: e.response && e.response.errors && e.response.errors.length ? e.response.errors[0]['message'] : 'Error',
      title: 'Saving Profile'
    });
  }
}

export const deleteProfile = async ({effects, actions}, data) => {
  try {
    return await effects.gql.mutations.deleteProfile(data)
  } catch (e) {
    console.log(e, 'deleteProfile errors')
    actions.alert.showError({
      message: e.response && e.response.errors && e.response.errors.length ? e.response.errors[0]['message'] : 'Error',
      title: 'Delete Profile'
    });
  }
}

export const archiveProfile = async ({ effects, actions }, data) => {
  try {
    return await effects.gql.mutations.archiveProfile(data);
  } catch (e) {
    console.log(e, "archiveProfile errors");
    actions.alert.showError({
      message:
        e.response && e.response.errors && e.response.errors.length
          ? e.response.errors[0]["message"]
          : "Error",
      title: "Archive Profile",
    });
  }
};

/*
*
*/
export const onChangePage = async ({state}, page) => {
  state.profile.activePage = page
}

/*
*
*/
export const onProfileAdded = ({state}, data) => {
  state.profile.push(data)
}

export const sendOutreachEmail = async ({effects}, data) => {
  return await effects.gql.mutations.sendOutreachEmail(data)
}
