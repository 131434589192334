import gql from "graphql-tag";
import { userFragment } from "@/store/effects/gql/user/fragments";
import { ventureFragment } from "@/store/effects/gql/venture/fragments";

export const programFragment = gql`
  {
    id
    name
    type
    duration
    admins${userFragment}
    company {
      id
    }
    cohorts {
      id
      name
      ventures {
        id
        name
        users {
          id firstName lastName email 
        }
		interviews {id isArchived}
		contacts {id isArchived}
		milestones{id name tagLabel}
		targetCustomerUsers {
			id
			interviews {id isArchived}
			contacts {id isArchived}
		}
		opportunities {
          id
          isArchived
          name
          adjustedMarket
          magnitudeScore
          hiMarketPercentage
          midMarketPercentage
          lowMarketPercentage
          outOfMarketPercentage
		  surveyOpportunities {
			id responses {id}
		  }
        }
      }
      startDate
      endDate
      users {
        id
        firstName
        lastName
        username
        email
      }
    }
    createdBy {
      id
    }
    createdAt
    updatedAt
  }
`;
