import gql from 'graphql-tag';

export const tagFragment = gql`{
  id
  name
	type
	category
  status
  sortOrder
  targetType
}`
